// VideosList.js
import React from 'react';
import { DataGrid } from '@mui/x-data-grid';
import '../styles/VideosList.css';

const VideosList = ({ videos, onVideoSelect }) => {
  const columns = [
    {
      field: 'AssignedTo',
      headerName: 'Owner',
      flex: 1,
      sortable: true,
      renderCell: (params) => <div className="owner-cell">{params.value}</div>,
    },
    {
      field: 'date',
      headerName: 'Date',
      flex: 1,
      sortable: true,
    },
    {
      field: 'time',
      headerName: 'Time',
      flex: 1,
      sortable: true,
    },
    {
      field: 'formattedDuration',
      headerName: 'Duration',
      flex: 1,
      sortable: true,
    },
  ];

  const rows = videos.map((video) => ({
    id: video.id,
    AssignedTo: video.assignedTo || 'Unknown',
    date: new Date(video.TimeRecorded).toLocaleDateString(),
    time: formatTime(new Date(video.TimeRecorded)),
    formattedDuration: formatDuration(parseInt(video.Duration)),
    ...video,
  }));

  return (
    <div className="videos-list-container">
      <div className="data-grid-container">
        <DataGrid
          rows={rows}
          columns={columns}
          pageSize={5}
          rowsPerPageOptions={[5, 10, 20]}
          onRowClick={(params) => onVideoSelect(params.row)}
          disableColumnMenu
          components={{
            Header: (props) => <div {...props} className="custom-header" />,
          }}
          componentsProps={{
            columnHeader: {
              style: {
                backgroundColor: '#fff',
              },
            },
          }}
          getRowHeight={() => 'auto'}
          disableColumnResize
          disableExtendRowFullWidth
          autoHeight={false}
        />
      </div>
    </div>
  );
};

// Helper function to format time without seconds
const formatTime = (date) => {
  return date.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
};

// Helper function to format duration
const formatDuration = (milliseconds) => {
  const seconds = Math.floor(milliseconds / 1000);
  const minutes = Math.floor(seconds / 60);
  const hours = Math.floor(minutes / 60);

  const remainingMinutes = minutes % 60;
  const remainingSeconds = seconds % 60;

  if (hours > 0) {
    return `${hours}h ${remainingMinutes}m ${remainingSeconds}s`;
  } else if (minutes > 0) {
    return `${minutes}m ${remainingSeconds}s`;
  } else {
    return `${seconds}s`;
  }
};

export default VideosList;
