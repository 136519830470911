import React from 'react';
import { Tooltip } from '@mui/material';

function MetricsDisplay({ metrics, deviceMetrics, isOverwatch }) {
  if (!isOverwatch) return null;

  const formatFraction = (fraction) => {
    return `${fraction.numerator} / ${fraction.denominator}`;
  };

  return (
    <div className="metrics-display">
      {/* {deviceMetrics && (
        <Tooltip
          title={<span style={{ fontSize: '0.8rem' }}>Devices in shift within the last 14 days</span>}
          placement="top-start"
          arrow
        >
          <div className="metric-pill">
            <span className="metric-label">Active Devices</span>
            <span className="metric-value">{formatFraction(deviceMetrics)}</span>
          </div>
        </Tooltip>
      )} */}
      {metrics.labelingCoverage && (
        <Tooltip
          title={<span style={{ fontSize: '0.8rem' }}>Escalations labeled in the last 14 days</span>}
          placement="top-start"
          arrow
        >
          <div className="metric-pill">
            <span className="metric-label">Labeled</span>
            <span className="metric-value">{formatFraction(metrics.labelingCoverage)}</span>
          </div>
        </Tooltip>
      )}
      {metrics.labelingAccuracy && (
        <Tooltip
          title={<span style={{ fontSize: '0.8rem' }}>Escalations labeled in last 14 days as correct</span>}
          placement="top-start"
          arrow
        >
          <div className="metric-pill">
            <span className="metric-label">Accuracy</span>
            <span className="metric-value">{formatFraction(metrics.labelingAccuracy)}</span>
          </div>
        </Tooltip>
      )}
    </div>
  );
}

export default MetricsDisplay;
