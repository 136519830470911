import React, { createContext, useContext, useState, useEffect } from 'react';

const AuthFlowContext = createContext();

export const useAuthFlow = () => useContext(AuthFlowContext);

export const AuthFlowProvider = ({ children }) => {
  const [hasStarted, setHasStarted] = useState(() => {
    // init state from localStorage to maintain state after page reloads
    return localStorage.getItem('hasStarted') === 'true';
  });

  useEffect(() => {
    // localStorage when hasStarted changes
    // this doesn't seem to work though for some reason
    localStorage.setItem('hasStarted', hasStarted);
  }, [hasStarted]);

  return <AuthFlowContext.Provider value={{ hasStarted, setHasStarted }}>{children}</AuthFlowContext.Provider>;
};
