import { CosmosClient } from '@azure/cosmos';
import { getQueryAndParams, PLIX_OVERWATCH_ACCOUNT } from '../utils/utilsEvents';
import { getCoordinatesFromHash } from './utilsGeo';
import pLimit from 'p-limit';

const cosmosClient = new CosmosClient({
  endpoint: 'https://plixeventstorage.documents.azure.com:443/',
  key: process.env.REACT_APP_AZURE_COSMOS_KEY,
});

const devicesContainer = cosmosClient.database('Devices').container('Devices');
const organizationsContainer = cosmosClient.database('Organizations').container('Organizations');

const shiftsContainer = cosmosClient.database('Shifts').container('Shifts');

const limit = pLimit(6);

export const fetchDeviceConfigs = async (userIdOrOrgId) => {
  try {
    let query;
    if (userIdOrOrgId === PLIX_OVERWATCH_ACCOUNT) {
      // Fetch all devices for overwatch
      query = {
        query: 'SELECT * FROM c',
      };
    } else {
      // Fetch devices for specific user or org
      query = {
        query: 'SELECT * FROM c WHERE c.userId = @userId OR c.orgUserId = @userId',
        parameters: [{ name: '@userId', value: userIdOrOrgId }],
      };
    }

    const { resources: devices } = await devicesContainer.items.query(query).fetchAll();
    return devices;
  } catch (error) {
    console.error('Error fetching device configs:', error);
    return [];
  }
};

export const fetchLatestDeviceLog = async (deviceId) => {
  const container = cosmosClient.database('DeviceLogs').container('DeviceLogs');
  const querySpec = {
    query: 'SELECT TOP 1 * FROM c WHERE c.deviceId = @deviceId ORDER BY c.timestamp DESC',
    parameters: [{ name: '@deviceId', value: deviceId }],
  };
  const {
    resources: [latestLog],
  } = await container.items.query(querySpec).fetchAll();
  return latestLog;
};

const mapDeviceState = (action) => {
  switch (action) {
    case 'Start Shift':
      return 'In Shift';
    case 'End Shift':
      return 'Offline';
    case 'Start Recording':
      return 'Recording';
    case 'Stop Recording':
      return 'In Shift';
    default:
      return 'Unknown';
  }
};

export const fetchDeviceStates = async (deviceIds) => {
  try {
    if (!Array.isArray(deviceIds) || deviceIds.length === 0) {
      console.error('Invalid deviceIds:', deviceIds);
      return {};
    }

    const now = new Date();

    // Fetch Timescale data
    const deviceQuery = deviceIds.map((id) => `device.eq.${id}`).join(',');
    const timescaleBaseUrl = process.env.REACT_APP_TIMESCALE_BASE_URL;
    const url = `${timescaleBaseUrl}/get_shift_state?or=(${deviceQuery})&order=time.desc`;
    // console.log('Timescale query URL:', url);
    const timescaleResponse = await fetch(url, {
      headers: {
        Authorization: `Bearer ${process.env.REACT_APP_TIMESCALE_API_KEY}`,
      },
    });
    const timescaleData = await timescaleResponse.json();
    console.log('timescale data: ', timescaleData);

    const timescaleStates = timescaleData.reduce((acc, item) => {
      acc[item.device] = {
        shiftStatus: item.shift,
        lastShiftTime: new Date(item.time),
      };
      return acc;
    }, {});

    // Fetch Cosmos DB data
    const fetchCosmosData = async (deviceId) => {
      const query = {
        query: `
          SELECT TOP 1 c.startTime, c.events, c.inProgress, c.duration, c.lastSeen
          FROM c
          WHERE c.deviceId = @deviceId
          AND (NOT IS_DEFINED(c.duration) OR (c.duration >= 60))
          ORDER BY c.startTime DESC
        `,
        parameters: [{ name: '@deviceId', value: deviceId }],
      };
      const { resources } = await shiftsContainer.items.query(query).fetchAll();
      if (resources.length > 0) {
        return {
          [deviceId]: resources[0],
        };
      }
      return { [deviceId]: null };
    };

    const cosmosDataArray = await Promise.all(deviceIds.map((deviceId) => limit(() => fetchCosmosData(deviceId))));
    const cosmosData = Object.assign({}, ...cosmosDataArray);

    // Combine and adjust data
    const finalDeviceStates = {};
    for (const deviceId of deviceIds) {
      const timescaleState = timescaleStates[deviceId];
      const cosmosState = cosmosData[deviceId];

      let shiftStatus, lastShiftTime, duration, isOffline, lastSeen;

      // Check for low battery end event in Cosmos data
      const events = cosmosState?.events || [];
      const lastEvent = events[events.length - 1];
      const isLowBatteryEnd = lastEvent && lastEvent.type === 'end' && lastEvent.batteryLevel <= 1;

      // Determine shift status and last shift time
      if (isLowBatteryEnd) {
        shiftStatus = false;
        lastShiftTime = new Date(lastEvent.timestamp).toISOString();
      } else {
        // Determine shift status from Timescale or Cosmos
        if (timescaleState !== undefined) {
          shiftStatus = timescaleState.shiftStatus;
        } else if (cosmosState?.inProgress) {
          shiftStatus = true;
        } else {
          shiftStatus = false;
        }
        console.log('Initial shiftStatus from Timescale/Cosmos: ', shiftStatus);
        // console.log('Cosmos state: ', cosmosState);

        // Check if Cosmos data indicates an active shift
        if (!shiftStatus && cosmosState && cosmosState.inProgress && cosmosState.lastSeen) {
          const lastSeenTime = new Date(cosmosState.lastSeen);
          const timeDiff = now - lastSeenTime;
          if (timeDiff > 10 * 60 * 1000) {
            shiftStatus = true;
            console.log(
              `Overriding shiftStatus for device ${deviceId} to true based on Cosmos data (last seen within 10 minutes)`
            );
          } else {
            console.log(
              `Cosmos data indicates active shift, but last seen more than 10 minutes ago for device ${deviceId}`
            );
          }
        }

        console.log('Final shiftStatus for device ', deviceId, ': ', shiftStatus);

        // Determine last shift time
        if (shiftStatus) {
          lastShiftTime = 'Now';
        } else if (cosmosState) {
          if (lastEvent) {
            lastShiftTime = new Date(lastEvent.timestamp).toISOString();
          } else {
            lastShiftTime = new Date(cosmosState.startTime).toISOString();
          }
        } else {
          lastShiftTime = null;
        }
      }

      // Determine duration and offline status
      if (shiftStatus && cosmosState && cosmosState.inProgress) {
        const shiftStartTime = new Date(cosmosState.startTime);
        const now = new Date();
        console.log('cosmosState: ', cosmosState);
        lastSeen = cosmosState.lastSeen ? new Date(cosmosState.lastSeen) : now;
        const timeDifference = lastSeen - shiftStartTime;
        // Check if device is offline (30 minutes threshold)
        isOffline = now - lastSeen > 30 * 60 * 1000;
        duration = Math.round(timeDifference / (1000 * 60));
        console.log('Device: ', deviceId, 'Last seen: ', lastSeen, 'Duration: ', duration);
        // console.log('Device: ', deviceId, 'Duration: ', duration);
      } else {
        duration = null;
        isOffline = false;
        lastSeen = null;
      }

      finalDeviceStates[deviceId] = {
        shiftStatus,
        lastShiftTime,
        duration,
        isOffline,
        lastSeen: lastSeen ? lastSeen.toISOString() : null,
        recording_audio: false, // We don't have this info, so defaulting to false
      };

      // Log the requested information
      console.log(`Device ${deviceId}:
        Timescale shift status: ${timescaleState ? timescaleState.shiftStatus : 'N/A'}
        Final shift status: ${shiftStatus}
        Last shift time: ${lastShiftTime}
        Duration: ${duration !== null ? `${duration} minutes` : 'N/A'}
        inProgress: ${cosmosState ? cosmosState.inProgress : 'N/A'}
        Cosmos last event: ${lastEvent ? `${lastEvent.type} (Battery: ${lastEvent.batteryLevel})` : 'N/A'}
        Last seen: ${lastSeen ? lastSeen.toISOString() : 'N/A'}
        raw last seen: ${cosmosState ? cosmosState.lastSeen : 'N/A'}
        Is offline: ${isOffline}
      `);
    }

    return finalDeviceStates;
  } catch (error) {
    console.error('Error fetching device states:', error);
    return deviceIds.reduce((acc, id) => {
      acc[id] = {
        shiftStatus: false,
        lastShiftTime: null,
        duration: null,
        isOffline: false,
        lastSeen: null,
        recording_audio: false,
      };
      return acc;
    }, {});
  }
};

export const fetchSignalStrengths = async (deviceIds) => {
  if (!deviceIds || deviceIds.length === 0) return {};

  try {
    const deviceQuery = deviceIds.map((id) => `device.eq.${id}`).join(',');
    const timescaleBaseUrl = process.env.REACT_APP_TIMESCALE_BASE_URL;
    const response = await fetch(`${timescaleBaseUrl}/latest_signals?or=(${deviceQuery})`, {
      headers: {
        Authorization: `Bearer ${process.env.REACT_APP_TIMESCALE_API_KEY}`,
      },
    });
    if (!response.ok) {
      if (response.status === 504) {
        return Object.fromEntries(deviceIds.map((id) => [id, 0]));
      }
      throw new Error(`HTTP error! status: ${response.status}`);
    }
    const data = await response.json();

    const signalStrengths = {};
    deviceIds.forEach((id) => {
      const deviceData = data.find((item) => item.device === id);
      signalStrengths[id] = deviceData ? deviceData.level : 1;
    });

    return signalStrengths;
  } catch (error) {
    console.error('Error fetching signal strengths:', error);
    return Object.fromEntries(deviceIds.map((id) => [id, 1]));
  }
};

const MIN_VALID_TEMP_C = -60;
const MAX_VALID_TEMP_C = 85;

const fetchDeviceStatus = async (deviceId) => {
  try {
    const timescaleBaseUrl = process.env.REACT_APP_TIMESCALE_BASE_URL;
    const response = await fetch(`${timescaleBaseUrl}/battery?device=eq.${deviceId}&order=time.desc&limit=1`, {
      headers: {
        Authorization: `Bearer ${process.env.REACT_APP_TIMESCALE_API_KEY}`,
      },
    });
    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }
    const [data] = await response.json();

    if (data) {
      return {
        deviceId: data.device,
        isRecording: data.recording_audio,
        temperature:
          data.temperature >= MIN_VALID_TEMP_C && data.temperature <= MAX_VALID_TEMP_C ? data.temperature : null,
        isCharging: data.charging,
        batteryLevel: data.level,
        lastUpdateTime: new Date(data.time),
      };
    }
    return null;
  } catch (error) {
    console.error(`Error fetching status for device ${deviceId}:`, error);
    return null;
  }
};

const fetchLastChargeAndRecordTimes = async (deviceId) => {
  try {
    const timescaleBaseUrl = process.env.REACT_APP_TIMESCALE_BASE_URL;
    const chargeQuery = `${timescaleBaseUrl}/battery?device=eq.${deviceId}&charging=eq.true&order=time.desc&limit=1`;
    const recordQuery = `${timescaleBaseUrl}/battery?device=eq.${deviceId}&recording_audio=eq.true&order=time.desc&limit=1`;

    const [chargeResponse, recordResponse] = await Promise.all([
      fetch(chargeQuery, {
        headers: {
          Authorization: `Bearer ${process.env.REACT_APP_TIMESCALE_API_KEY}`,
        },
      }),
      fetch(recordQuery, {
        headers: {
          Authorization: `Bearer ${process.env.REACT_APP_TIMESCALE_API_KEY}`,
        },
      }),
    ]);

    const chargeData = await chargeResponse.json();
    const recordData = await recordResponse.json();

    const lastChargeTime = chargeData[0]?.time || null;
    const lastRecordTime = recordData[0]?.time || null;

    console.log(`Device ${deviceId} last charge time:`, lastChargeTime);
    console.log(`Device ${deviceId} last record time:`, lastRecordTime);

    return { lastChargeTime, lastRecordTime };
  } catch (error) {
    console.error(`Error fetching last charge and record times for device ${deviceId}:`, error);
    return { lastChargeTime: null, lastRecordTime: null };
  }
};

export const pingDevices = async (userId, lastChargeAndRecordOnly = false) => {
  try {
    const devices = await fetchDeviceConfigs(userId);
    const deviceIds = devices.map((device) => device.deviceId);

    // console.log('Devices found:', devices.filter((d) => d.deviceId === 'plixbwc49'));
    console.log('lastChargeAndRecordOnly: ', lastChargeAndRecordOnly);

    if (lastChargeAndRecordOnly) {
      const lastChargeAndRecordTimes = await Promise.all(deviceIds.map(fetchLastChargeAndRecordTimes));
      return devices.map((device, index) => ({
        deviceId: device.deviceId,
        ...lastChargeAndRecordTimes[index],
      }));
    }

    const [deviceStatuses, deviceStates, latestLogs, latestLocations, lastChargeAndRecordTimes] = await Promise.all([
      Promise.all(deviceIds.map(fetchDeviceStatus)),
      fetchDeviceStates(deviceIds),
      Promise.all(deviceIds.map((deviceId) => fetchLatestDeviceLog(deviceId))),
      fetchLatestLocations(deviceIds),
      Promise.all(deviceIds.map(fetchLastChargeAndRecordTimes)),
    ]);

    return devices
      .map((device, index) => {
        // Early return if device is undefined or missing required properties
        if (!device || !device.deviceId) {
          console.error('Invalid device object encountered:', device);
          return null;
        }

        const state = deviceStates[device.deviceId] || {
          shiftStatus: false,
          lastShiftTime: null,
          duration: null,
          isOffline: false,
        };
        const latestLog = latestLogs.find((log) => log && log.deviceId === device.deviceId);
        const timescaleLocation = latestLocations[device.deviceId];
        const deviceStatus = deviceStatuses[index];
        const { lastChargeTime, lastRecordTime } = lastChargeAndRecordTimes[index] || {};
        let lastLocatedTime;

        // Default location if device coordinates are missing
        let location = [null, null];

        if (device.lastLocationUpdated && timescaleLocation && timescaleLocation.time) {
          const deviceLastUpdate = new Date(device.lastLocationUpdated);
          const timescaleLastUpdate = new Date(timescaleLocation.time);

          if (deviceLastUpdate > timescaleLastUpdate) {
            location = [device.latitude, device.longitude];
            lastLocatedTime = deviceLastUpdate;
          } else {
            location = [timescaleLocation.latitude, timescaleLocation.longitude];
            lastLocatedTime = timescaleLastUpdate;
          }
        } else if (timescaleLocation && timescaleLocation.latitude !== null && timescaleLocation.longitude !== null) {
          location = [timescaleLocation.latitude, timescaleLocation.longitude];
          lastLocatedTime = new Date(timescaleLocation.time);
        } else {
          location = [device.latitude, device.longitude];
          lastLocatedTime = null;
        }

        const result = {
          deviceId: device.deviceId || 'unknown',
          location: location,
          config: { streamAddress: device.streamUrl || null },
          isStreamActive: device.isVideoActive || false,
          isAlertsActive: true,
          deviceStatus: device.deviceStatus || 'unknown',
          deviceState: state.shiftStatus ? 'In Shift' : 'Offline',
          deviceName: device.deviceName || 'Unnamed Device',
          batteryStatus: deviceStatus ? deviceStatus.batteryLevel : device.batteryStatus || null,
          latitude: location[0],
          longitude: location[1],
          lastLocatedTime: lastLocatedTime,
          userId: device.userId,
          fcmToken: device.fcmToken,
          assignedTo: device.assignedTo,
          latestLog: latestLog ? { action: latestLog.action, buttonId: latestLog.buttonId } : null,
          liveViewPolicy: device.liveViewPolicy,
          shiftStatus: state.shiftStatus,
          lastShiftTime: state.lastShiftTime,
          timeInShift: state.duration,
          temperature: deviceStatus ? deviceStatus.temperature : null,
          isRecording: state.shiftStatus ? (deviceStatus ? deviceStatus.isRecording : false) : false,
          isCharging: deviceStatus ? deviceStatus.isCharging : false,
          isOffline: state.isOffline,
          lastUpdateTime: deviceStatus ? deviceStatus.lastUpdateTime : null,
          lastChargeTime,
          lastRecordTime,
        };

        console.log('Result for device ', device.deviceId, ': ', result);
        return result;
      })
      .filter(Boolean); // Remove any null entries from invalid devices
  } catch (error) {
    console.error('Error in pingDevices:', error);
    return [];
  }
};

async function fetchLatestLocations(deviceIds) {
  const fetchLatestForDevice = async (deviceId) => {
    const timescaleBaseUrl = process.env.REACT_APP_TIMESCALE_BASE_URL;
    const url = `${timescaleBaseUrl}/device_location?device=eq.${deviceId}&order=time.desc&limit=1`;
    try {
      const response = await fetch(url, {
        headers: {
          Authorization: `Bearer ${process.env.REACT_APP_TIMESCALE_API_KEY}`,
        },
      });
      const data = await response.json();
      if (data.length > 0) {
        const { latitude, longitude } = getCoordinatesFromHash(data[0].location);
        return { deviceId, latitude, longitude, time: data[0].time };
      }
      return { deviceId, latitude: null, longitude: null, time: null };
    } catch (error) {
      console.error(`Error fetching latest location for device ${deviceId}:`, error);
      return { deviceId, latitude: null, longitude: null, time: null };
    }
  };

  try {
    const locationPromises = deviceIds.map(fetchLatestForDevice);
    const locations = await Promise.all(locationPromises);
    return locations.reduce((acc, location) => {
      acc[location.deviceId] = location;
      return acc;
    }, {});
  } catch (error) {
    console.error('Error fetching latest locations:', error);
    return {};
  }
}

const determineDeviceState = (deviceId, state, latestLog) => {
  if (state) {
    if (state.recording_audio) return 'Recording';
    if (state.shiftStatus) return 'In Shift';
    return 'Offline';
  }
  // Fallback to Cosmos DB data if device not found in new data
  return latestLog ? mapDeviceState(latestLog.action) : 'Unknown';
};

export const updateDeviceConfig = async (deviceId, updates, userId) => {
  try {
    const baseQuery = 'SELECT * FROM c WHERE c.deviceId = @deviceId AND c.userId = @userId';
    const { query, parameters } = getQueryAndParams(baseQuery, userId, [{ name: '@deviceId', value: deviceId }]);

    const {
      resources: [existingDevice],
    } = await devicesContainer.items.query({ query, parameters }).fetchAll();
    if (!existingDevice) {
      console.error('userId, deviceId: ', userId, deviceId);
      throw new Error('Device not found or you do not have permission to update this device');
    }
    const updatedDevice = { ...existingDevice, ...updates };
    await devicesContainer.items.upsert(updatedDevice);
  } catch (error) {
    console.error('Error updating device config:', error);
    throw error; // Rethrow the error to be caught by the calling function
  }
};
