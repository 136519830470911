import React from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { useAuthFlow } from '../contexts/AuthFlowContext';
import '../styles/Home.css';

const Home = () => {
  const { loginWithRedirect } = useAuth0();
  const { setHasStarted } = useAuthFlow();

  const handleGetStarted = () => {
    setHasStarted(true);
    loginWithRedirect();
  };

  return (
    <div className="home-container">
      <h1>Welcome to Plix</h1>
      <button className="modern-button" onClick={handleGetStarted}>
        Get Started
      </button>
    </div>
  );
};

export default Home;
