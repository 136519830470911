const { CosmosClient } = require('@azure/cosmos');

const cosmosEndpoint = 'https://plixeventstorage.documents.azure.com:443/';
const cosmosClient = new CosmosClient({
  endpoint: cosmosEndpoint,
  key: process.env.REACT_APP_AZURE_COSMOS_KEY,
  connectionPolicy: {
    enableEndpointDiscovery: false,
  },
});

const isSasUrlExpired = (sasUrl) => {
  const url = new URL(sasUrl);
  const params = new URLSearchParams(url.search);
  const expiry = params.get('se');
  if (!expiry) {
    return false;
  }
  const expiryDate = new Date(expiry);
  const now = new Date();
  return now > expiryDate;
};

async function renewSasUrl(blobUrl) {
  try {
    const response = await fetch('/api/renewSasUrl', {
      // Adjust the path as needed based on your Azure Function's route
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ blobUrl, newExpiryHours: 24 }),
    });
    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }
    const data = await response.json();
    console.log('data: ', data);
    return data.newSasUrl;
  } catch (error) {
    console.error('Failed to renew SAS URL:', error);
    return blobUrl; // Fallback to the original URL on failure
  }
}

module.exports = { cosmosClient, isSasUrlExpired, renewSasUrl };
