import { useState, useCallback, useEffect, useRef } from 'react';
import L from 'leaflet';
import '../../../../../../../styles/NewDashboard/ShiftReview/ShiftCard/ShiftExpanded/EventInfo/SingleMapView.css';
import 'leaflet/dist/leaflet.css';

// This component will update the map's center when the location changes
// const ChangeMapView = ({ center }) => {
//   const map = useMap();
//   useEffect(() => {
//     map.setView(center, 17);
//   }, [center, map]);
//   return null;
// };

const SingleMapView = ({ location, size = 'large' }) => {
  const [map, setMap] = useState(null);
  const [isValidLocation, setIsValidLocation] = useState(false);
  const markerRef = useRef(null);

  useEffect(() => {
    setIsValidLocation(location && typeof location.latitude === 'number' && typeof location.longitude === 'number');
  }, [location]);

  const createCustomIcon = useCallback(() => {
    return L.divIcon({
      className: 'custom-icon',
      html: `<svg width="50" height="50" viewBox="0 0 50 50" xmlns="http://www.w3.org/2000/svg">
               <circle cx="25" cy="25" r="20" fill="red" opacity="0.4"/>
             </svg>`,
      iconSize: [50, 50],
      iconAnchor: [25, 25],
      popupAnchor: [0, -25],
    });
  }, []);

  const center = isValidLocation ? [location.latitude, location.longitude] : [0, 0];

  const displayMap = useCallback(
    (node) => {
      if (node !== null && map === null && isValidLocation) {
        const newMap = L.map(node, {
          center,
          zoom: 17,
          zoomControl: true,
          dragging: true,
          scrollWheelZoom: true,
          doubleClickZoom: true,
          attributionControl: false,
        });

        L.tileLayer(
          `https://api.mapbox.com/styles/v1/mapbox/satellite-streets-v11/tiles/{z}/{x}/{y}?access_token=${process.env.REACT_APP_MAPBOX_PUBLIC_TOKEN}`,
          {
            attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors',
          }
        ).addTo(newMap);

        markerRef.current = L.marker(center, { icon: createCustomIcon() }).addTo(newMap);

        setMap(newMap);
      }
    },
    [map, center, isValidLocation, createCustomIcon]
  );

  useEffect(() => {
    if (map && isValidLocation) {
      map.setView(center, 17);
      if (markerRef.current) {
        markerRef.current.setLatLng(center);
      }
    }
  }, [map, center, isValidLocation]);

  if (!isValidLocation) {
    return <div className={`single-map-view-container ${size}`}>Location not available.</div>;
  }

  return (
    <div
      className={`single-map-view-container ${size}`}
      style={{ height: size === 'large' ? '600px' : '300px' }}
      ref={displayMap}
    />
  );
};

export default SingleMapView;
