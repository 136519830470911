import React from 'react';
import ReactDOM from 'react-dom';

export const Modal = ({ isOpen, onClose, children, modalDevice }) => {
  if (!isOpen) return null;

  return ReactDOM.createPortal(
    <div
      onClick={onClose}
      style={{
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        backgroundColor: 'rgba(0, 0, 0, 0.7)',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        padding: '15px',
        zIndex: 10000,
      }}
    >
      <div
        onClick={(e) => e.stopPropagation()}
        style={{
          backgroundColor: 'white',
          padding: '20px',
          position: 'relative',
          borderRadius: '8px',
          paddingTop: '50px',
        }}
      >
        <button
          className="modal-close"
          onClick={onClose}
          style={{
            position: 'absolute',
            top: '10px',
            right: '10px',
            border: 'none',
            background: 'none',
            fontSize: '1.5rem',
            color: 'black',
          }}
        >
          ×
        </button>
        {/* Device ID displayed in the top left */}
        <span
          style={{
            position: 'absolute',
            top: '15px',
            left: '20px',
            fontSize: '1.1rem',
            fontWeight: 'bold',
            color: 'black',
            fontFamily: 'Montserrat, sans-serif',
          }}
        >
          <span className="recording-dot"></span>
          {modalDevice.deviceId}
        </span>
        {children}
      </div>
    </div>,
    document.body
  );
};
