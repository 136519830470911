// SiteInfo.js
import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser, faClock, faCalendar, faMapMarkerAlt, faMapPin, faStopwatch } from '@fortawesome/free-solid-svg-icons';
import { formatDuration } from '../../../../../../../utils/timeUtils';

const SiteInfo = ({ event, assignedTo, shiftEndTime, isShiftInProgress }) => {
  const startTime = new Date(event.timestamp);
  const endTime = event.exitTime ? new Date(event.exitTime) : isShiftInProgress ? new Date() : new Date(shiftEndTime);
  const duration = endTime - startTime;

  const formatTimeRange = (start, end) => {
    return `${start.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })} \u2013 ${end.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}`;
  };

  const formatDateRange = (start, end) => {
    const startDate = start.toLocaleDateString([], { month: 'long', day: 'numeric', year: 'numeric' });
    const endDate = end.toLocaleDateString([], { month: 'long', day: 'numeric', year: 'numeric' });

    if (startDate === endDate) {
      return startDate;
    }
    return `${startDate} \u2013 ${endDate}`;
  };

  return (
    <div className="location-info-container">
      <div className="location-info-header">
        <h3>Site</h3>
      </div>
      <div className="location-details">
        <div className="location-detail-row">
          <div className="location-detail-icon">
            <FontAwesomeIcon icon={faUser} />
          </div>
          <div className="location-detail-value">{assignedTo || 'N/A'}</div>
        </div>
        <div className="location-detail-row">
          <div className="location-detail-icon">
            <FontAwesomeIcon icon={faStopwatch} />
          </div>
          <div className="location-detail-value">{formatDuration(duration)}</div>
        </div>
        <div className="location-detail-row">
          <div className="location-detail-icon">
            <FontAwesomeIcon icon={faClock} />
          </div>
          <div className="location-detail-value">{formatTimeRange(startTime, endTime)}</div>
        </div>
        <div className="location-detail-row">
          <div className="location-detail-icon">
            <FontAwesomeIcon icon={faCalendar} />
          </div>
          <div className="location-detail-value">{formatDateRange(startTime, endTime)}</div>
        </div>
        <div className="location-detail-row">
          <div className="location-detail-icon">
            <FontAwesomeIcon icon={faMapMarkerAlt} />
          </div>
          <div className="location-detail-value">{event.geofenceId}</div>
        </div>
      </div>
    </div>
  );
};

export default SiteInfo;
