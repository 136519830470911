import { isSasUrlExpired, renewSasUrl } from './eventstorage';

export const PLIX_OVERWATCH_ACCOUNT = 'auth0|6679cda801de4d750dd341a0';

export function getQueryAndParams(baseQuery, userId, additionalParams = []) {
  if (userId === PLIX_OVERWATCH_ACCOUNT) {
    return {
      query: baseQuery
        .replace('WHERE c.userId = @userId AND ', 'WHERE ')
        .replace('WHERE c.userId = @userId', 'WHERE 1=1'),
      parameters: additionalParams.filter((param) => param.name !== '@userId'),
    };
  } else {
    return {
      query: baseQuery,
      parameters: [{ name: '@userId', value: userId }, ...additionalParams],
    };
  }
}

function deduplicateCards(cards, dedupeTimeRange = 10) {
  console.log('cards: ', cards);
  let result = [];
  let lastSeen = new Map();

  cards.forEach((card) => {
    const { Label, Timestamp } = card;
    const cardTime = new Date(Timestamp).getTime();

    // Check if there's a recent card with the same label within the time range
    if (lastSeen.has(Label)) {
      const lastTime = lastSeen.get(Label);
      // console.log('lastTime: ', lastTime, ' cardTime: ', cardTime)
      if (lastTime - cardTime <= dedupeTimeRange * 1000) {
        // If within range, skip this card
        lastSeen.set(Label, cardTime);
        return;
      }
    }
    lastSeen.set(Label, cardTime);
    result.push(card);
  });

  return result;
}

// Helper function to find state abbreviation by title
function findStateAbbreviationByTitle(transitionsConfig, title) {
  const transition = transitionsConfig.find((transition) => transition.title === title);
  return transition ? transition.name : null;
}

// Helper to initialize possible states based on the label of the last card
function findInitialPossibleStates(config, lastCardLabel) {
  const lastCardStateAbbreviation = findStateAbbreviationByTitle(config.transitionsConfig, lastCardLabel);
  // console.log('lastCardStateAbbreviation: ', lastCardStateAbbreviation)
  const possibleStates = [];

  Object.entries(config.statesConfig).forEach(([state, config]) => {
    if (Object.keys(config.validTransitions).includes(lastCardStateAbbreviation)) {
      possibleStates.push(state);
    }
  });

  return possibleStates;
}

function deduplicateStatefulCards(config, cards) {
  if (!cards.length) return [];

  let possibleStates = findInitialPossibleStates(config, cards[cards.length - 1].Label);
  let result = [];

  // Iterate from the last card to the first card
  for (let i = cards.length - 1; i >= 0; i--) {
    const card = cards[i];
    const cardStateAbbreviation = findStateAbbreviationByTitle(config.transitionsConfig, card.Label);

    // console.log('possibleStates: ', possibleStates)
    // console.log('cardStateAbbreviation: ', cardStateAbbreviation)
    let newPossibleStates = [];
    let isValidTransition = false;

    // Check each possible state to see if the current card's state abbreviation is a valid transition
    possibleStates.forEach((state) => {
      const stateConfig = config.statesConfig[state];
      if (stateConfig.validTransitions[cardStateAbbreviation]) {
        isValidTransition = true;
        // Collect new possible states that can be transitioned to via the current card's state abbreviation
        newPossibleStates.push(stateConfig.validTransitions[cardStateAbbreviation]);
      }
    });

    if (isValidTransition && (result.length === 0 || card.Label !== result[0].Label)) {
      result.unshift(card); // Add the card only once if it's valid for any state
      // Update possible states to those that are reachable given the current card's state
      possibleStates = newPossibleStates;
    }
  }

  return result;
}

export async function fetchOlderCards(
  config,
  oldestNewCardTimestamp,
  setFilteredOldCards,
  filterStarred,
  alertsContainer
) {
  if (!oldestNewCardTimestamp) {
    setFilteredOldCards([]);
    return;
  }

  let query = `SELECT * FROM c WHERE c.Timestamp < @oldestTimestamp`;
  if (filterStarred) {
    query += ` AND c.Starred = true`;
  }
  query += ` ORDER BY c.Timestamp DESC OFFSET 0 LIMIT 200`;

  const querySpec = {
    query: query,
    parameters: [{ name: '@oldestTimestamp', value: oldestNewCardTimestamp }],
  };

  try {
    const { resources: olderCards } = await alertsContainer.items.query(querySpec).fetchAll();
    const updatedCards = await Promise.all(
      olderCards.map(async (card) => {
        if (card.Thumbnail && isSasUrlExpired(card.Thumbnail)) {
          const newThumbnailUrl = await renewSasUrl(card.Thumbnail);
          await alertsContainer.item(card.id, card.DeviceId).replace({ ...card, Thumbnail: newThumbnailUrl });
          return { ...card, Thumbnail: newThumbnailUrl };
        }
        return card;
      })
    );
    setFilteredOldCards(
      deduplicateStatefulCards(
        config,
        updatedCards.sort((a, b) => new Date(b.Timestamp) - new Date(a.Timestamp))
      )
    );
  } catch (error) {
    console.error('Error fetching older cards:', error);
    setFilteredOldCards([]);
  }
}

export default fetchOlderCards;
