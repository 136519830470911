import React, { useState, memo, useEffect, useRef, useCallback } from 'react';
import '../styles/Transcript.css';
import { PLIX_OVERWATCH_ACCOUNT } from '../utils/utilsEvents';

const Transcript = memo(
  ({
    alert,
    getPlayerState,
    onTranscriptClick,
    userId,
    inShiftExpanded,
    transcriptUrl,
    defaultShowTranscript = true,
  }) => {
    const [showTranscript, setShowTranscript] = useState(defaultShowTranscript);
    const [transcripts, setTranscripts] = useState([]);
    const transcriptContainerRef = useRef(null);
    const [activeTranscriptId, setActiveTranscriptId] = useState(null);
    const lastScrolledTranscriptId = useRef(null);

    // Parse VTT file if URL is provided
    const parseVTT = async (url) => {
      try {
        const response = await fetch(url);
        const text = await response.text();
        const lines = text.split('\n');
        const parsedTranscripts = [];
        let index = 0;

        for (let i = 0; i < lines.length; i++) {
          if (lines[i].includes('-->')) {
            const timings = lines[i].split(' --> ');
            const startTime = convertVTTTimeToSeconds(timings[0]);
            const transcript = lines[i + 1];

            parsedTranscripts.push({
              relativeTimestamp: startTime,
              transcript: transcript,
              id: index++,
            });
          }
        }

        setTranscripts(parsedTranscripts);
      } catch (error) {
        console.error('Error parsing VTT file:', error);
      }
    };

    const convertVTTTimeToSeconds = (vttTime) => {
      const [hours, minutes, seconds] = vttTime.split(':');
      return parseFloat(hours) * 3600 + parseFloat(minutes) * 60 + parseFloat(seconds.replace(',', '.'));
    };

    // Initialize transcripts based on source (VTT or alert)
    useEffect(() => {
      if (transcriptUrl) {
        parseVTT(transcriptUrl);
      } else if (alert) {
        const isOverwatch = userId === PLIX_OVERWATCH_ACCOUNT;
        const rawTranscripts = isOverwatch
          ? [...(alert.Transcripts || []), ...(alert.VideoTranscripts || [])]
          : alert.VideoTranscripts || alert.Transcripts || [];

        setTranscripts(
          rawTranscripts.map((item, index) => ({
            ...item,
            relativeTimestamp: item.relativeTimestamp ?? 0,
            id: index,
          }))
        );
      }
    }, [transcriptUrl, alert, userId]);

    const formatTimestamp = (seconds) => {
      const minutes = Math.floor(seconds / 60);
      const remainingSeconds = Math.floor(seconds % 60);
      return `${minutes.toString().padStart(2, '0')}:${remainingSeconds.toString().padStart(2, '0')}`;
    };

    const isTranscriptActive = useCallback((currentItem, nextItem, currentTime) => {
      if (!nextItem) {
        return currentTime >= currentItem.relativeTimestamp;
      }
      return currentTime >= currentItem.relativeTimestamp && currentTime < nextItem.relativeTimestamp;
    }, []);

    const scrollToTranscript = useCallback((transcriptId) => {
      console.log('PLYR: Entering scrollToTranscript function');
      console.log(
        'PLYR: transcriptId=',
        transcriptId,
        'type:',
        typeof transcriptId,
        'lastScrolledTranscriptId.current=',
        lastScrolledTranscriptId.current,
        'transcriptContainerRef.current exists:',
        !!transcriptContainerRef.current
      );

      const transcriptElement = document.getElementById(`transcript-${transcriptId}`);
      console.log('PLYR: transcriptElement exists:', !!transcriptElement);

      if (transcriptId !== undefined && transcriptContainerRef.current && transcriptElement) {
        console.log('PLYR: Inside main if statement');
        console.log('PLYR: transcriptElement innerHTML:', transcriptElement.innerHTML);

        const container = transcriptContainerRef.current;
        const targetScrollTop = transcriptElement.offsetTop - container.offsetTop;

        console.log('PLYR: Scrolling to:', targetScrollTop);

        container.scrollTo({
          top: targetScrollTop,
          behavior: 'smooth',
        });

        lastScrolledTranscriptId.current = transcriptId;
        console.log('PLYR: Scroll complete');
      } else {
        console.log('PLYR: Conditions not met for scrolling');
      }
    }, []);

    useEffect(() => {
      const updateActiveTranscript = () => {
        const { currentTime } = getPlayerState ? getPlayerState() : { currentTime: 0 };
        let activeTranscript = null;
        for (let i = transcripts.length - 1; i >= 0; i--) {
          if (transcripts[i].relativeTimestamp <= currentTime) {
            activeTranscript = transcripts[i];
            break;
          }
        }

        if (activeTranscript) {
          setActiveTranscriptId(activeTranscript.relativeTimestamp);
        }
      };

      const intervalId = setInterval(updateActiveTranscript, 1000);

      return () => clearInterval(intervalId);
    }, [getPlayerState, transcripts]);

    useEffect(() => {
      if (showTranscript && activeTranscriptId !== null) {
        scrollToTranscript(activeTranscriptId);
      }
    }, [activeTranscriptId, showTranscript, scrollToTranscript]);

    return (
      <div className={`alert-description ${inShiftExpanded ? 'in-shift-expanded' : ''}`}>
        {showTranscript && transcripts.length > 0 && (
          <div className="transcript-box" ref={transcriptContainerRef}>
            {transcripts.map((item, index) => {
              const nextItem = index < transcripts.length - 1 ? transcripts[index + 1] : null;
              const isActive = isTranscriptActive(item, nextItem, getPlayerState?.()?.currentTime || 0);
              return (
                <div
                  key={index}
                  id={`transcript-${item.relativeTimestamp}`}
                  className={`transcript-line ${isActive ? 'active' : ''}`}
                  onClick={() => onTranscriptClick(item.relativeTimestamp)}
                >
                  <span className="transcript-timestamp">{formatTimestamp(item.relativeTimestamp)}</span>
                  <p className="transcript-text">
                    <span className={item.isEscalation ? 'escalation-text' : ''}>{item.transcript}</span>
                  </p>
                </div>
              );
            })}
          </div>
        )}
        {transcripts && transcripts.length > 0 && (
          <button className="view-transcript-button" onClick={() => setShowTranscript(!showTranscript)}>
            {showTranscript ? 'Hide Transcript' : 'View Transcript'}
          </button>
        )}
      </div>
    );
  }
);

export default Transcript;
