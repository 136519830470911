/**
 * Formats a duration in milliseconds to a human-readable string.
 * @param {number} durationInMs - The duration in milliseconds.
 * @returns {string} A formatted string representing the duration (e.g., "2h 30m").
 */
export const formatDuration = (durationInMs) => {
  const seconds = Math.floor(durationInMs / 1000);
  const hours = Math.floor(seconds / 3600);
  const minutes = Math.floor((seconds % 3600) / 60);
  const remainingSeconds = seconds % 60;

  if (seconds < 60) {
    return `${seconds}s`;
  }

  let result = '';
  if (hours > 0) {
    result += `${hours}h `;
  }
  if (minutes > 0 || hours > 0) {
    result += `${minutes}m`;
  }

  return result.trim();
};

// You can add more time-related utility functions here if needed
