import React, { createContext, useState, useContext } from 'react';
import { cosmosClient } from '../utils/eventstorage';

const AlertsContext = createContext();

export const useAlerts = () => useContext(AlertsContext);

export const AlertsProvider = ({ children, user }) => {
  const [currentState, setCurrentState] = useState('H');
  const [events, setEvents] = useState([]);
  const [cards, setCards] = useState([]);
  const [lastResettingCurrentState, setLastResettingCurrentState] = useState(Date.now());
  const database = cosmosClient.database('Alerts');
  const alertsContainer = database.container('StateMachineAlerts');

  const clearAlerts = async () => {
    try {
      // Assume alertsContainer is defined and available
      const querySpec = {
        query: 'SELECT c.id, c.DeviceId FROM c WHERE c.IsDemo = true',
      };
      const { resources: alertsToDelete } = await alertsContainer.items.query(querySpec).fetchAll();
      console.log('alertsToDelete: ', alertsToDelete);
      for (const alert of alertsToDelete) {
        await alertsContainer.item(alert.id, alert.DeviceId).delete();
      }
      setCurrentState('H');
      setEvents([]); // Alerts
      setCards([]);
      setLastResettingCurrentState(Date.now());
      console.log('All demo alerts have been deleted.');
    } catch (err) {
      console.error('Error clearing demo alerts:', err.message);
    }
  };

  return (
    <AlertsContext.Provider
      value={{
        currentState,
        setCurrentState,
        events,
        setEvents,
        cards,
        setCards,
        lastResettingCurrentState,
        setLastResettingCurrentState,
        clearAlerts,
      }}
    >
      {children}
    </AlertsContext.Provider>
  );
};
