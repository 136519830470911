// A Caleb Special
function hashCode(str, seed = 0) {
  let h1 = 0xdeadbeef ^ seed,
    h2 = 0x41c6ce57 ^ seed;
  for (let i = 0, ch; i < str.length; i++) {
    ch = str.charCodeAt(i);
    h1 = Math.imul(h1 ^ ch, 2654435761);
    h2 = Math.imul(h2 ^ ch, 1597334677);
  }
  h1 = Math.imul(h1 ^ (h1 >>> 16), 2246822507);
  h1 ^= Math.imul(h2 ^ (h2 >>> 13), 3266489909);
  h2 = Math.imul(h2 ^ (h2 >>> 16), 2246822507);
  h2 ^= Math.imul(h1 ^ (h1 >>> 13), 3266489909);

  return 4294967296 * (2097151 & h2) + (h1 >>> 0);
}

export function stringToColor(str) {
  let hash = hashCode(str);
  const hue = hash % 360;
  return `hsl(${hue}, 100%, 90%)`; // Adjust the lightness for pastel tones
}

const ALPHABET = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
const BASE = ALPHABET.length;

export const encode = (num) => {
  let str = '';
  while (num > 0) {
    str = ALPHABET[num % BASE] + str;
    num = Math.floor(num / BASE);
  }
  return str.padStart(6, 'A');
};

export const decode = (str) => {
  let num = 0;
  for (let i = 0; i < str.length; i++) {
    num = num * BASE + ALPHABET.indexOf(str[i]);
  }
  return num;
};

export const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

export const formatTimestamp = (seconds) => {
  if (seconds < 60) {
    return `${seconds}s`;
  }
  const minutes = Math.floor(seconds / 60);
  const remainingSeconds = seconds % 60;
  return `${minutes}m ${remainingSeconds}s`;
};
