import React, { useState, useMemo, useCallback, useEffect, useRef } from 'react';
import { format } from 'date-fns';
import { DataGrid } from '@mui/x-data-grid';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash, faPen, faPlus, faInfoCircle } from '@fortawesome/free-solid-svg-icons';
// import { deleteGeofence } from '../utils/dashboardTimescaleQueries';
import { addGeofence, updateGeofence, deleteGeofence } from '../api/beaverApi';
import { stringToColor } from '../utils/utilsUI';
import AddressAutocomplete from './AddressAutocomplete';
import AddressAutocompleteWrapper from './AddressAutocompleteWrapper';
import { Modal, Box, Typography } from '@mui/material';
import GeofenceControls from './GeofenceControls';

import { getDistance } from 'geolib';
import '../styles/SiteAssignment.css';

const DEFAULT_RADIUS = 150; // in meters

const formatLastShiftTime = (lastShiftTime, shiftStatus, timeInShift) => {
  if (shiftStatus) {
    const formattedTime = formatTimeInShift(timeInShift);
    return formattedTime ? `Now (${formattedTime})` : 'Now';
  }
  if (!lastShiftTime) return '';
  const date = new Date(lastShiftTime);
  const now = new Date();
  if (date > now) return 'Future date';
  if (date.toDateString() === now.toDateString()) {
    return `Today at ${format(date, 'h:mm a')}`;
  }
  if (date.toDateString() === new Date(now.getTime() - 86400000).toDateString()) {
    return `Yesterday at ${format(date, 'h:mm a')}`;
  }
  if (date.getFullYear() === now.getFullYear()) {
    return format(date, 'M/d h:mm a');
  }
  return format(date, 'M/d/yyyy h:mm a');
};

const formatTimeInShift = (timeInShift) => {
  if (timeInShift === null) return '';
  if (timeInShift < 1) return 'Just started';
  if (timeInShift < 60) return `${timeInShift}m`;

  const hours = Math.floor(timeInShift / 60);
  const remainingMinutes = timeInShift % 60;
  return `${hours}h ${remainingMinutes}m`;
};

const calculateDistance = (lat1, lon1, lat2, lon2) => {
  const R = 6371e3;
  const φ1 = (lat1 * Math.PI) / 180;
  const φ2 = (lat2 * Math.PI) / 180;
  const Δφ = ((lat2 - lat1) * Math.PI) / 180;
  const Δλ = ((lon2 - lon1) * Math.PI) / 180;

  const a = Math.sin(Δφ / 2) * Math.sin(Δφ / 2) + Math.cos(φ1) * Math.cos(φ2) * Math.sin(Δλ / 2) * Math.sin(Δλ / 2);
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

  return R * c;
};

const PersonnelPill = React.memo(({ name, deviceId, onClick }) => {
  const backgroundColor = stringToColor(name);

  return (
    <span
      style={{
        backgroundColor,
        color: '#000',
        padding: '2px 8px',
        borderRadius: '12px',
        margin: '2px 3px',
        display: 'inline-block',
        fontSize: '0.7rem',
        lineHeight: '1.4',
        whiteSpace: 'nowrap',
        cursor: 'pointer',
      }}
      onClick={() => onClick(deviceId)}
    >
      {name}
    </span>
  );
});

const SiteAssignment = React.memo(
  ({
    geofences,
    devices,
    deviceShifts,
    onSiteClick,
    organizationId,
    onGeofenceUpdate,
    handleDeviceSelect,
    onPreviewGeofence,
    selectedGeofence,
    setSelectedGeofence,
  }) => {
    const [localGeofences, setLocalGeofences] = useState(geofences);
    const [editedRow, setEditedRow] = useState(null);
    const [selectedAddress, setSelectedAddress] = useState(null);
    const [modalOpen, setModalOpen] = useState(false);
    const [selectedSite, setSelectedSite] = useState(null);
    const [previewGeofence, setPreviewGeofence] = useState(null);
    const [showGeofenceControls, setShowGeofenceControls] = useState(false);
    const [activeGeofence, setActiveGeofence] = useState(null);
    const [isGeofenceModified, setIsGeofenceModified] = useState(false);
    const originalGeofenceRef = useRef(null);

    useEffect(() => {
      setLocalGeofences(geofences);
    }, [geofences]);

    const handleDeleteGeofence = useCallback(
      async (id) => {
        try {
          await deleteGeofence(id, organizationId);
          const updatedGeofences = localGeofences.filter((geofence) => geofence.id !== id);
          setLocalGeofences(updatedGeofences);
          onGeofenceUpdate(updatedGeofences);
        } catch (error) {
          console.error('Failed to delete geofence:', error);
          // Optionally, show an error message to the user
        }
      },
      [organizationId]
    );

    const handleUpdateGeofence = useCallback(
      async (newRow) => {
        const geofence = localGeofences.find((g) => g.id === newRow.id);
        if (!geofence) return newRow;

        try {
          const result = await updateGeofence(
            newRow.id,
            organizationId,
            newRow.nickname,
            [geofence.longitude, geofence.latitude],
            geofence.radius
          );

          if (result.success) {
            const updatedGeofences = localGeofences.map((g) =>
              g.id === newRow.id ? { ...g, nickname: newRow.nickname } : g
            );
            setLocalGeofences(updatedGeofences);
            onGeofenceUpdate(updatedGeofences);
            setEditedRow(newRow);
            return newRow;
          } else {
            console.error('Failed to update geofence:', result.error);
            return geofence;
          }
        } catch (error) {
          console.error('Error updating geofence:', error);
          return geofence;
        }
      },
      [organizationId, localGeofences]
    );

    const siteData = useMemo(() => {
      const uniqueGeofences = localGeofences.reduce((acc, current) => {
        const x = acc.find((item) => item.id === current.id);
        if (!x) {
          return acc.concat([current]);
        } else {
          return acc;
        }
      }, []);

      return uniqueGeofences
        .map((site) => {
          const nearbyDevices = devices.filter(
            (device) =>
              getDistance(
                { latitude: site.latitude, longitude: site.longitude },
                { latitude: device.latitude, longitude: device.longitude }
              ) <= site.radius
          );

          const activeDevices = nearbyDevices.filter((device) => deviceShifts[device.deviceId]?.shiftStatus === true);

          const activePersonnel = activeDevices
            .map((device) => ({
              name: device.assignedTo || device.deviceName,
              deviceId: device.deviceId,
            }))
            .filter((person) => Boolean(person.name));

          return {
            id: site.id,
            nickname: site.nickname || site.id,
            place: site.id,
            personnelInShift: activePersonnel,
            latitude: site.latitude,
            longitude: site.longitude,
            radius: site.radius,
          };
        })
        .sort((a, b) => a.nickname.localeCompare(b.nickname));
    }, [localGeofences, devices, deviceShifts]);

    console.log('siteData: ', siteData);

    const handlePersonnelClick = useCallback(
      (deviceId) => {
        console.log('personnel click: ', deviceId);
        handleDeviceSelect(deviceId);
      },
      [handleDeviceSelect]
    );

    const columns = useMemo(
      () => [
        {
          field: 'nickname',
          headerName: (
            <div>
              Nickname <FontAwesomeIcon icon={faPen} style={{ marginLeft: '4px', fontSize: '0.6rem' }} />
            </div>
          ),
          width: 150,
          editable: true,
          sortable: true,
          renderCell: (params) => (
            <div
              style={{
                fontSize: '0.8rem',
                padding: '8px 0',
                whiteSpace: 'normal',
                wordWrap: 'break-word',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                width: '100%',
              }}
            >
              {params.value}
              {/* <FontAwesomeIcon
                icon={faInfoCircle}
                style={{ cursor: 'pointer', marginLeft: '8px' }}
                onClick={(e) => {
                  e.stopPropagation();
                  setSelectedSite(params.row);
                  setModalOpen(true);
                }}
              /> */}
            </div>
          ),
        },
        {
          field: 'personnelInShift',
          headerName: 'In Shift Now',
          width: 200,
          sortable: false,
          filterable: false,
          disableColumnMenu: true,
          renderCell: (params) => {
            const personnel = params.value;
            return (
              <div
                style={{
                  display: 'flex',
                  flexWrap: 'wrap',
                  maxHeight: '100%',
                  overflow: 'hidden',
                  alignItems: 'flex-start',
                  alignContent: 'flex-start',
                  gap: '4px 3px',
                  padding: '4px 0',
                }}
              >
                {personnel.map((person, index) => (
                  <PersonnelPill
                    key={index}
                    name={person.name}
                    deviceId={person.deviceId}
                    onClick={handlePersonnelClick}
                  />
                ))}
              </div>
            );
          },
        },
        {
          field: 'actions',
          headerName: '',
          width: 50,
          sortable: false,
          filterable: false,
          disableColumnMenu: true,
          renderCell: (params) => (
            <div style={{ width: '100%', textAlign: 'center' }}>
              <FontAwesomeIcon
                icon={faTrash}
                onClick={(e) => {
                  e.stopPropagation();
                  handleDeleteGeofence(params.row.id);
                }}
                style={{ cursor: 'pointer' }}
              />
            </div>
          ),
        },
      ],
      [handleDeleteGeofence]
    );

    const handleRowClick = (params) => {
      handleGeofenceSelect(params.row);
    };

    const handleProcessRowUpdate = async (newRow) => {
      return handleUpdateGeofence(newRow);
    };

    const handleAddressSelect = useCallback(
      (result) => {
        console.log('geofences: Address selected in SiteAssignment', result);
        setSelectedAddress(result);
        const newPreviewGeofence = {
          id: 'preview',
          nickname: result.place_name,
          latitude: result.center[1],
          longitude: result.center[0],
          radius: DEFAULT_RADIUS,
          full_address: result.full_address, // Add this line
        };
        setPreviewGeofence(newPreviewGeofence);
        setActiveGeofence(newPreviewGeofence);
        onPreviewGeofence(newPreviewGeofence);
        onSiteClick(result.center[1], result.center[0]);
      },
      [onPreviewGeofence, onSiteClick]
    );

    const handleAddressInputChange = useCallback(() => {
      setSelectedAddress(null);
    }, []);

    const handleAddGeofence = useCallback(async () => {
      if (!selectedAddress) return;

      // Check if a geofence with the same name already exists
      const existingGeofence = localGeofences.find((geofence) => geofence.id === selectedAddress.full_address);

      if (existingGeofence) {
        console.error('A geofence with this name already exists.');
        return; // Exit the function if a duplicate is found
      }

      const newGeofence = {
        id: selectedAddress.full_address, // Use full_address as the id
        nickname: selectedAddress.place_name,
        name: selectedAddress.full_address, // Use full_address as the name
        organization: organizationId,
        center: selectedAddress.center,
        latitude: selectedAddress.center[1],
        longitude: selectedAddress.center[0],
        radius: DEFAULT_RADIUS,
      };

      try {
        const result = await addGeofence(
          newGeofence.name,
          newGeofence.organization,
          newGeofence.center,
          newGeofence.radius
        );
        if (result.success) {
          const updatedGeofences = [...geofences, newGeofence];
          setLocalGeofences(updatedGeofences);
          setSelectedAddress(null);
          setPreviewGeofence(null);
          onGeofenceUpdate(updatedGeofences);
          onPreviewGeofence(null);
        } else {
          console.error('geofences: Failed to add geofence:', result.error);
        }
      } catch (error) {
        console.error('geofences: Error adding geofence:', error);
      }
    }, [selectedAddress, organizationId, geofences, onGeofenceUpdate, onPreviewGeofence, localGeofences]);

    const handleGeofenceSelect = useCallback(
      (geofence) => {
        setSelectedGeofence(geofence);
        setActiveGeofence(geofence);
        setShowGeofenceControls(true);
        setPreviewGeofence(null);
        onPreviewGeofence(null);
        // Update this line to pass more information to onSiteClick
        onSiteClick(geofence.latitude, geofence.longitude, geofence.radius);
        originalGeofenceRef.current = { ...geofence };
        setIsGeofenceModified(false);
      },
      [onSiteClick, onPreviewGeofence, setSelectedGeofence]
    );

    const handleGeofenceSave = useCallback(
      async (updatedGeofence) => {
        if (updatedGeofence.id === 'preview') {
          // Check if a geofence with the same name already exists
          const existingGeofence = localGeofences.find(
            (geofence) => geofence.id === selectedAddress.full_address || geofence.name === updatedGeofence.nickname
          );

          if (existingGeofence) {
            console.error('A geofence with this name or ID already exists.');
            return; // Exit the function if a duplicate is found
          }

          // Handle adding new geofence
          const newGeofenceId = selectedAddress.full_address; // Use full address as the ID
          const result = await addGeofence(
            newGeofenceId, // Use full address as the name/id in the database
            organizationId,
            [updatedGeofence.longitude, updatedGeofence.latitude],
            updatedGeofence.radius,
            updatedGeofence.nickname // Pass the nickname
          );
          if (result.success) {
            const newGeofence = {
              ...updatedGeofence,
              id: newGeofenceId,
              name: newGeofenceId, // Set name to full address
              nickname: updatedGeofence.nickname, // Preserve the user-entered nickname
            };
            setLocalGeofences([...localGeofences, newGeofence]);
            onGeofenceUpdate([...localGeofences, newGeofence]);
          } else {
            console.error('Failed to add geofence:', result.error);
          }
        } else {
          // Handle updating existing geofence
          const result = await updateGeofence(
            updatedGeofence.id,
            organizationId,
            updatedGeofence.nickname,
            [updatedGeofence.longitude, updatedGeofence.latitude],
            updatedGeofence.radius
          );
          if (result.success) {
            const updatedGeofences = localGeofences.map((g) =>
              g.id === updatedGeofence.id ? { ...g, nickname: updatedGeofence.nickname } : g
            );
            setLocalGeofences(updatedGeofences);
            onGeofenceUpdate(updatedGeofences);
          } else {
            console.error('Failed to update geofence:', result.error);
          }
        }
        setShowGeofenceControls(false);
        setSelectedGeofence(null);
        setPreviewGeofence(null);
        setActiveGeofence(null);
        onPreviewGeofence(null);
        setIsGeofenceModified(false);
        originalGeofenceRef.current = null;
      },
      [localGeofences, organizationId, onGeofenceUpdate, onPreviewGeofence, selectedAddress]
    );

    const handleGeofenceCancel = useCallback(() => {
      if (originalGeofenceRef.current) {
        setActiveGeofence(originalGeofenceRef.current);
        if (originalGeofenceRef.current.id !== 'preview') {
          const updatedGeofences = localGeofences.map((g) =>
            g.id === originalGeofenceRef.current.id ? originalGeofenceRef.current : g
          );
          setLocalGeofences(updatedGeofences);
          onGeofenceUpdate(updatedGeofences);
        }
      }
      setShowGeofenceControls(false);
      setSelectedGeofence(null);
      setPreviewGeofence(null);
      setActiveGeofence(null);
      onPreviewGeofence(null);
      setIsGeofenceModified(false);
      originalGeofenceRef.current = null;
    }, [onPreviewGeofence, localGeofences, onGeofenceUpdate]);

    const handleRadiusChange = useCallback(
      (newRadius) => {
        if (activeGeofence) {
          const updatedGeofence = { ...activeGeofence, radius: newRadius };
          setActiveGeofence(updatedGeofence);
          if (updatedGeofence.id === 'preview') {
            onPreviewGeofence(updatedGeofence);
          } else {
            // Update existing geofence
            const updatedGeofences = localGeofences.map((g) => (g.id === updatedGeofence.id ? updatedGeofence : g));
            setLocalGeofences(updatedGeofences);
            onGeofenceUpdate(updatedGeofences);
          }
        }
      },
      [activeGeofence, onPreviewGeofence, localGeofences, onGeofenceUpdate]
    );

    const handleGeofenceChange = useCallback(
      (updatedGeofence) => {
        setActiveGeofence(updatedGeofence);
        setIsGeofenceModified(true);
        if (updatedGeofence.id === 'preview') {
          onPreviewGeofence(updatedGeofence);
        }
      },
      [onPreviewGeofence]
    );

    return (
      <div className="site-assignment-container">
        <div className="data-grid-container">
          <DataGrid
            rows={siteData}
            columns={columns}
            pageSize={100}
            rowsPerPageOptions={[100]}
            disableSelectionOnClick
            onRowClick={handleRowClick}
            processRowUpdate={handleUpdateGeofence}
            onProcessRowUpdateError={(error) => {
              console.error('Error updating row:', error);
            }}
            components={{
              Header: (props) => <div {...props} className="custom-header" />,
            }}
            componentsProps={{
              columnHeader: {
                style: {
                  height: 30,
                  fontSize: '0.875rem',
                  lineHeight: '30px',
                },
              },
            }}
            hideFooter={siteData.length <= 100}
            hideFooterPagination={siteData.length <= 100}
            autoHeight={false}
            getRowHeight={() => 'auto'}
            disableColumnResize
            disableExtendRowFullWidth
          />
        </div>
        <div className="address-search-container">
          <AddressAutocompleteWrapper onSelect={handleAddressSelect} onInputChange={handleAddressInputChange} />
          {/* <button className="add-geofence-button" onClick={handleAddGeofence} disabled={!selectedAddress}>
            <FontAwesomeIcon icon={faPlus} />
          </button> */}
        </div>
        <Modal
          open={modalOpen}
          onClose={() => setModalOpen(false)}
          aria-labelledby="site-info-modal"
          aria-describedby="site-info-description"
        >
          <Box
            sx={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              width: 400,
              bgcolor: 'background.paper',
              border: '2px solid #000',
              boxShadow: 24,
              p: 4,
            }}
          >
            <Typography id="site-info-modal" variant="h6" component="h2">
              Site Information
            </Typography>
            <Typography id="site-info-description" sx={{ mt: 2 }}>
              Nickname: {selectedSite?.nickname}
              <br />
              Full Address: {selectedSite?.id}
              <br />
              Latitude: {selectedSite?.latitude}
              <br />
              Longitude: {selectedSite?.longitude}
              <br />
              Radius: {selectedSite?.radius} meters
            </Typography>
          </Box>
        </Modal>
        {(showGeofenceControls || previewGeofence) && activeGeofence && (
          <GeofenceControls
            geofence={activeGeofence}
            onSave={handleGeofenceSave}
            onCancel={handleGeofenceCancel}
            onChange={handleGeofenceChange}
            isPreview={activeGeofence.id === 'preview'}
            onRadiusChange={handleRadiusChange}
            isModified={isGeofenceModified}
          />
        )}
      </div>
    );
  }
);

export default SiteAssignment;
