import React, { useState, useEffect, useRef } from 'react';
import { format, differenceInDays, startOfWeek, endOfWeek, addWeeks, subWeeks, isBefore, isAfter } from 'date-fns';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCalendarAlt,
  faBolt,
  faBatteryFull,
  faChevronLeft,
  faChevronRight,
  faSpinner,
  faCrown,
} from '@fortawesome/free-solid-svg-icons';
import '../styles/Leaderboard.css';

// Copy from plix-beaver
const METRICS = {
  SHIFT_ACTIVATION: { weight: 0.3, maxDaily: 4 },
  ACTIVATION_RATE: { weight: 0.4, maxDaily: 6 },
  BATTERY_LEVEL: { weight: 0.3, maxDaily: 4 },
};

const Leaderboard = ({ leaderboardData, onWeekChange, fetchLeaderboardData, singleDeviceId }) => {
  const [expandedRow, setExpandedRow] = useState(null);
  const [currentWeekStart, setCurrentWeekStart] = useState(startOfWeek(new Date(), { weekStartsOn: 1 }));
  const singleGuardRef = useRef(null);

  useEffect(() => {
    if (!leaderboardData) {
      fetchLeaderboardData(currentWeekStart);
    } else if (singleDeviceId) {
      const guardIndex = leaderboardData.rankings.findIndex((rank) => rank.deviceId === singleDeviceId);
      if (guardIndex !== -1) {
        setExpandedRow(guardIndex);
        setTimeout(() => {
          singleGuardRef.current?.scrollIntoView({ behavior: 'smooth', block: 'start' });
        }, 100);
      }
    }
  }, [currentWeekStart, fetchLeaderboardData, leaderboardData, singleDeviceId]);

  useEffect(() => {
    if (!leaderboardData) {
      fetchLeaderboardData(currentWeekStart);
    }
  }, [currentWeekStart, fetchLeaderboardData, leaderboardData]);

  const handlePreviousWeek = () => {
    const newWeekStart = subWeeks(currentWeekStart, 1);
    setCurrentWeekStart(newWeekStart);
    onWeekChange(newWeekStart);
  };

  const handleNextWeek = () => {
    const newWeekStart = addWeeks(currentWeekStart, 1);
    setCurrentWeekStart(newWeekStart);
    onWeekChange(newWeekStart);
  };

  const handleRowClick = (index) => {
    setExpandedRow(expandedRow === index ? null : index);
  };

  if (!leaderboardData) {
    return (
      <div className="leaderboard-loading">
        <FontAwesomeIcon icon={faSpinner} spin />
        <p>Loading leaderboard...</p>
      </div>
    );
  }

  console.log('leaderboardData: ', leaderboardData);

  if (leaderboardData.rankings.length === 0) {
    return <div className="leaderboard-no-data">No leaderboard data available for this organization.</div>;
  }

  const { rankings } = leaderboardData;
  const weekEnd = endOfWeek(currentWeekStart, { weekStartsOn: 1 });
  const formattedDateRange = `${format(currentWeekStart, 'M/d')} - ${format(weekEnd, 'M/d')}`;
  const daysLeft = differenceInDays(weekEnd, new Date()) + 1;

  const sortedRankings = rankings.sort((a, b) => b.totalScore - a.totalScore);

  const isCurrentWeek = isBefore(new Date(), endOfWeek(currentWeekStart, { weekStartsOn: 1 }));
  const isMoreThanThreeWeeksAgo = isBefore(currentWeekStart, subWeeks(new Date(), 3));

  const getTodayOrYesterdayScore = (dailyScores, guardName) => {
    const now = new Date();
    const today = now.toLocaleDateString('en-CA'); // Format: YYYY-MM-DD in local time
    const yesterday = new Date(now.setDate(now.getDate() - 1)).toLocaleDateString('en-CA');

    const todayScore = dailyScores.find((score) => score.date === today);
    const yesterdayScore = dailyScores.find((score) => score.date === yesterday);

    if (todayScore && todayScore.total) {
      return { score: todayScore.total, label: 'Today' };
    }
    if (yesterdayScore && yesterdayScore.total) {
      return { score: yesterdayScore.total, label: 'Yesterday' };
    }
    return null;
  };

  const getRecentBreakdown = (dailyScores) => {
    const now = new Date();
    const today = now.toLocaleDateString('en-CA');
    const yesterday = new Date(now.setDate(now.getDate() - 1)).toLocaleDateString('en-CA');

    const todayScore = dailyScores.find((score) => score.date === today);
    const yesterdayScore = dailyScores.find((score) => score.date === yesterday);

    return (
      todayScore?.breakdown || {
        shiftActivation: 0,
        activationRate: 0,
        batteryLevel: 0,
      }
    );
  };

  return (
    <div className="leaderboard">
      <div className="date-range-container">
        {!isMoreThanThreeWeeksAgo ? (
          <button className="arrow-button" onClick={handlePreviousWeek}>
            <FontAwesomeIcon icon={faChevronLeft} />
          </button>
        ) : (
          <button className="arrow-button arrow-button-placeholder">
            <FontAwesomeIcon icon={faChevronLeft} />
          </button>
        )}
        <div className="date-range">{formattedDateRange}</div>
        {!isCurrentWeek ? (
          <button className="arrow-button" onClick={handleNextWeek}>
            <FontAwesomeIcon icon={faChevronRight} />
          </button>
        ) : (
          <button className="arrow-button arrow-button-placeholder">
            <FontAwesomeIcon icon={faChevronRight} />
          </button>
        )}
      </div>
      <div className="days-left">
        {isCurrentWeek
          ? daysLeft > 0
            ? `${daysLeft} ${daysLeft === 1 ? 'day' : 'days'} left`
            : 'Week completed'
          : 'Final'}
      </div>
      <div className="rankings">
        {sortedRankings.map((rank, index) => {
          const recentScore = getTodayOrYesterdayScore(rank.dailyScores, rank.guardName);
          const recentBreakdown = getRecentBreakdown(rank.dailyScores);
          return (
            <div
              key={rank.deviceId}
              ref={rank.deviceId === singleDeviceId ? singleGuardRef : null}
              className={`rank-row ${expandedRow === index ? 'expanded' : ''}`}
              onClick={() => handleRowClick(index)}
            >
              <div className="rank-row-top">
                <div className="rank-number">{index < 10 ? `#${index + 1}` : ''}</div>
                <div className="rank-content">
                  <div className="guard-name-container">
                    <span className="guard-name">{rank.guardName}</span>
                    {index === 0 && <FontAwesomeIcon icon={faCrown} className="crown-icon" />}
                  </div>
                  <div className="score-container">
                    {recentScore && (
                      <div className="recent-score">
                        <div className="recent-score-value">+{Math.round(recentScore.score)}</div>
                        <div className="recent-score-label">{recentScore.label}</div>
                      </div>
                    )}
                    <div className="total-score">{Math.round(rank.weeklyTotal)}</div>
                  </div>
                </div>
              </div>
              <div className="expanded-content">
                <div className="score-details-container">
                  <div className="score-detail">
                    <FontAwesomeIcon icon={faCalendarAlt} />
                    <div className="score-value">{Math.round(rank.weeklyBreakdown.shiftActivation)}</div>
                    <div className="score-description">points for activating daily</div>
                  </div>
                  <div className="score-detail">
                    <FontAwesomeIcon icon={faBolt} />
                    <div className="score-value">{Math.round(rank.weeklyBreakdown.activationRate)}</div>
                    <div className="score-description">for activating throughout shift</div>
                  </div>
                  <div className="score-detail">
                    <FontAwesomeIcon icon={faBatteryFull} />
                    <div className="score-value">{Math.round(rank.weeklyBreakdown.batteryLevel)}</div>
                    <div className="score-description">for starting with full charge</div>
                  </div>
                </div>
                <div className="todays-gains-box">
                  <div className="fractions-container">
                    <div className="score-fraction">
                      {Math.round(recentBreakdown.shiftActivation)} / {METRICS.SHIFT_ACTIVATION.maxDaily}
                    </div>
                    <div className="score-fraction">
                      {Math.round(recentBreakdown.activationRate)} / {METRICS.ACTIVATION_RATE.maxDaily}
                    </div>
                    <div className="score-fraction">
                      {Math.round(recentBreakdown.batteryLevel)} / {METRICS.BATTERY_LEVEL.maxDaily}
                    </div>
                  </div>
                  <div className="todays-gains">Today's Gains</div>
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default Leaderboard;
