import React from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowUpRightFromSquare, faUser, faClock, faCalendar, faVideo } from '@fortawesome/free-solid-svg-icons';
import { Tooltip } from '@mui/material';
import { formatDuration } from '../../../../../../../utils/timeUtils';

const RecordingInfo = ({ event, assignedTo }) => {
  const startTime = new Date(event.timestamp);
  const durationMs = parseInt(event.duration, 10); // Convert duration to a number
  const endTime = new Date(startTime.getTime() + durationMs);

  const formatTimeRange = (start, end) => {
    return `${start.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })} \u2013 ${end.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}`;
  };

  const formatDateRange = (start, end) => {
    const startDate = start.toLocaleDateString([], { month: 'long', day: 'numeric', year: 'numeric' });
    const endDate = end.toLocaleDateString([], { month: 'long', day: 'numeric', year: 'numeric' });

    if (startDate === endDate) {
      return startDate;
    }
    return `${startDate} \u2013 ${endDate}`;
  };

  const handleGoToRecording = () => {
    const encodedFilename = encodeURIComponent(event.fileName);
    return `/videos?filename=${encodedFilename}&timestamp=0`;
  };

  return (
    <div className="location-info-container">
      <div className="location-info-header">
        <h3>Recording</h3>
        <div className="header-spacer">
          <Tooltip title="Go to recording">
            <Link to={handleGoToRecording()} className="info-button recording-available" title="View Video">
              <FontAwesomeIcon icon={faArrowUpRightFromSquare} className="info-icon" style={{ color: 'gray' }} />
            </Link>
          </Tooltip>
        </div>
      </div>
      <div className="location-details">
        <div className="location-detail-row">
          <div className="location-detail-icon">
            <FontAwesomeIcon icon={faUser} />
          </div>
          <div className="location-detail-value">{assignedTo || 'N/A'}</div>
        </div>
        <div className="location-detail-row">
          <div className="location-detail-icon">
            <FontAwesomeIcon icon={faClock} />
          </div>
          <div className="location-detail-value">{formatTimeRange(startTime, endTime)}</div>
        </div>
        <div className="location-detail-row">
          <div className="location-detail-icon">
            <FontAwesomeIcon icon={faCalendar} />
          </div>
          <div className="location-detail-value">{formatDateRange(startTime, endTime)}</div>
        </div>
        <div className="location-detail-row">
          <div className="location-detail-icon">
            <FontAwesomeIcon icon={faVideo} />
          </div>
          <div className="location-detail-value">{formatDuration(durationMs)}</div>
        </div>
      </div>
    </div>
  );
};

export default RecordingInfo;
