import React from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TextField,
  Select,
  MenuItem,
  IconButton,
  Tooltip,
  Typography,
} from '@mui/material';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle, faTrash, faPaperPlane } from '@fortawesome/free-solid-svg-icons';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import { styled } from '@mui/material';
import { useState } from 'react';

dayjs.extend(utc);
dayjs.extend(timezone);

const ReportSubscribersTable = ({ subscribers, setSubscribers, setUnsavedChanges, isOverwatch, handleSendNow }) => {
  const [localTimeZone] = useState(Intl.DateTimeFormat().resolvedOptions().timeZone);

  const handleChange = (index, field, value) => {
    const newSubscribers = [...subscribers];
    if (field === 'timeOfDay') {
      const localTime = dayjs(value).format('HH:mm');
      newSubscribers[index][field] = localTime;
      newSubscribers[index]['timeZone'] = localTimeZone;
    } else if (field === 'frequency' || field === 'reportType') {
      newSubscribers[index][field] = value.toLowerCase();
    } else {
      newSubscribers[index][field] = value;
    }
    setSubscribers(newSubscribers);
    setUnsavedChanges(true);
  };

  const handleRemoveSubscriber = (index) => {
    const newSubscribers = subscribers.filter((_, i) => i !== index);
    setSubscribers(newSubscribers);
    setUnsavedChanges(true);
  };

  const validateEmail = (email) => {
    const re =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  };

  // Filter subscribers for display, but keep original indices
  const displaySubscribers = subscribers
    .map((subscriber, index) => ({ ...subscriber, originalIndex: index }))
    .filter((subscriber) => isOverwatch || !subscriber.emailAddress.endsWith('plix.ai'));

  const StyledTooltip = styled(({ className, ...props }) => <Tooltip {...props} classes={{ popper: className }} />)(
    ({ theme }) => ({
      '& .MuiTooltip-tooltip': {
        backgroundColor: 'white',
        color: 'rgba(0, 0, 0, 0.87)',
        boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
        fontSize: 14,
        border: '1px solid #dadde9',
        maxWidth: 320,
        padding: theme.spacing(2),
        '& b': {
          fontWeight: 600,
        },
      },
    })
  );

  const filteredSubscribers = isOverwatch
    ? subscribers
    : subscribers.filter((subscriber) => !subscriber.emailAddress.endsWith('plix.ai'));

  const StyledIconButton = styled(IconButton)(({ theme }) => ({
    padding: theme.spacing(1.5),
    marginRight: theme.spacing(1),
    '&:hover': {
      backgroundColor: 'rgba(0, 0, 0, 0.04)',
    },
  }));

  return (
    <TableContainer component={Paper}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Email Address</TableCell>
            <TableCell>Frequency</TableCell>
            <TableCell style={{ width: '150px' }}>Time of Day</TableCell>
            <TableCell>
              Report Type
              <StyledTooltip
                title={
                  <React.Fragment>
                    <Typography variant="body2" component="div">
                      <b>Sites:</b> Visits to each site.
                      <br />
                      <b>Personnel:</b> Shifts completed by each personnel.
                    </Typography>
                  </React.Fragment>
                }
              >
                <IconButton size="small">
                  <FontAwesomeIcon icon={faInfoCircle} />
                </IconButton>
              </StyledTooltip>
            </TableCell>
            <TableCell></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {displaySubscribers.map((subscriber) => (
            <TableRow key={subscriber.originalIndex}>
              <TableCell>
                <TextField
                  value={subscriber.emailAddress}
                  onChange={(e) => handleChange(subscriber.originalIndex, 'emailAddress', e.target.value)}
                  size="small"
                  error={!validateEmail(subscriber.emailAddress)}
                  helperText={!validateEmail(subscriber.emailAddress) ? 'Invalid email' : ''}
                />
              </TableCell>
              <TableCell>
                <Select
                  value={subscriber.frequency}
                  onChange={(e) => handleChange(subscriber.originalIndex, 'frequency', e.target.value)}
                  size="small"
                >
                  <MenuItem value="daily">Daily</MenuItem>
                  <MenuItem value="weekly">Every Monday</MenuItem>
                </Select>
              </TableCell>
              <TableCell>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <TimePicker
                    value={dayjs(subscriber.timeOfDay, 'HH:mm').tz(localTimeZone)}
                    onChange={(newValue) => handleChange(subscriber.originalIndex, 'timeOfDay', newValue)}
                    renderInput={(params) => <TextField {...params} size="small" style={{ width: '120px' }} />}
                  />
                </LocalizationProvider>
              </TableCell>
              <TableCell>
                <Select
                  value={subscriber.reportType}
                  onChange={(e) => handleChange(subscriber.originalIndex, 'reportType', e.target.value)}
                  size="small"
                >
                  <MenuItem value="site">Sites</MenuItem>
                  <MenuItem value="personnel">Personnel</MenuItem>
                </Select>
              </TableCell>
              <TableCell>
                <Tooltip title="Send Now">
                  <StyledIconButton onClick={() => handleSendNow(subscriber)}>
                    <FontAwesomeIcon icon={faPaperPlane} size="1x" />
                  </StyledIconButton>
                </Tooltip>
                <Tooltip title="Remove">
                  <StyledIconButton onClick={() => handleRemoveSubscriber(subscriber.originalIndex)}>
                    <FontAwesomeIcon icon={faTrash} size="1x" />
                  </StyledIconButton>
                </Tooltip>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default ReportSubscribersTable;
