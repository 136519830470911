import React, { createContext, useContext, useState, useEffect, useMemo, useCallback } from 'react';
import { fetchDeviceConfigs, pingDevices } from '../api/beaverApi';
import { PLIX_OVERWATCH_ACCOUNT } from '../utils/utilsEvents';
import debounce from 'lodash/debounce';

const DeviceFilterContext = createContext();

// Add this helper function
const formatTimeInShift = (milliseconds) => {
  const minutes = Math.floor(milliseconds / 60000);
  const hours = Math.floor(minutes / 60);
  const remainingMinutes = minutes % 60;
  return hours > 0 ? `${hours}h ${remainingMinutes}m` : `${minutes}m`;
};

// custom equality check for devices
// used to prevent unnecessary re-renders of components that use the device filter context
function devicesAreEqual(prevDevices, nextDevices) {
  if (prevDevices.length !== nextDevices.length) return false;
  for (let i = 0; i < prevDevices.length; i++) {
    const prevDevice = prevDevices[i];
    const nextDevice = nextDevices[i];

    // Compare date fields
    const datesEqual = (date1, date2) => {
      if (!date1 && !date2) return true;
      if (!date1 || !date2) return false;
      return date1.getTime() === date2.getTime();
    };

    if (
      prevDevice.deviceId !== nextDevice.deviceId ||
      prevDevice.location[0] !== nextDevice.location[0] ||
      prevDevice.location[1] !== nextDevice.location[1] ||
      prevDevice.config.streamAddress !== nextDevice.config.streamAddress ||
      prevDevice.assignedTo !== nextDevice.assignedTo ||
      prevDevice.deviceState !== nextDevice.deviceState ||
      prevDevice.fcmToken !== nextDevice.fcmToken ||
      prevDevice.liveViewPolicy !== nextDevice.liveViewPolicy ||
      prevDevice.deviceName !== nextDevice.deviceName ||
      prevDevice.batteryStatus !== nextDevice.batteryStatus ||
      !datesEqual(prevDevice.lastLocatedTime, nextDevice.lastLocatedTime) ||
      !datesEqual(prevDevice.lastUpdateTime, nextDevice.lastUpdateTime)
    ) {
      return false;
    }
  }
  return true;
}

export const DeviceFilterProvider = ({ children, userId, userRole }) => {
  const [devices, setDevices] = useState([]);
  const [deviceShifts, setDeviceShifts] = useState({});
  const [deviceFilter, setDeviceFilter] = useState(() => {
    const savedFilter = localStorage.getItem('deviceFilter');
    return savedFilter ? savedFilter : null;
  });
  const [selectedOrg, setSelectedOrg] = useState(() => localStorage.getItem('selectedOrg') || PLIX_OVERWATCH_ACCOUNT);
  const [isOnline, setIsOnline] = useState(navigator.onLine);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const handleOnline = () => setIsOnline(true);
    const handleOffline = () => setIsOnline(false);

    window.addEventListener('online', handleOnline);
    window.addEventListener('offline', handleOffline);

    return () => {
      window.removeEventListener('online', handleOnline);
      window.removeEventListener('offline', handleOffline);
    };
  }, []);

  useEffect(() => {
    if (userId === PLIX_OVERWATCH_ACCOUNT) {
      // Reset device filter when org changes
      setDeviceFilter(null);
      localStorage.removeItem('deviceFilter');
    }
  }, [selectedOrg, userId]);

  const debouncedFetchDevices = useCallback(
    debounce(async () => {
      if (!isOnline) {
        console.log('Network is offline. Skipping device fetch.');
        return;
      }
      try {
        setIsLoading(true);
        const effectiveUserId = userId === PLIX_OVERWATCH_ACCOUNT ? selectedOrg : userId;
        const pingedDevices = await pingDevices(effectiveUserId, false, userRole);

        // Parse dates from the response
        const devicesWithParsedDates = pingedDevices.map((device) => ({
          ...device,
          lastLocatedTime: device.lastLocatedTime ? new Date(device.lastLocatedTime) : null,
          lastUpdateTime: device.lastUpdateTime ? new Date(device.lastUpdateTime) : null,
          lastChargeTime: device.lastChargeTime ? new Date(device.lastChargeTime) : null,
          lastRecordTime: device.lastRecordTime ? new Date(device.lastRecordTime) : null,
          lastShiftTime:
            device.lastShiftTime === 'Now' ? 'Now' : device.lastShiftTime ? new Date(device.lastShiftTime) : null,
        }));

        // Separate devices and shift data
        // because shift data is high-frequency, we want to update it more frequently than devices
        // so we separate them and use a custom equality check to prevent unnecessary re-renders on the map

        const devicesWithoutShiftData = devicesWithParsedDates.map(
          ({
            shiftStatus,
            lastShiftTime,
            timeInShift,
            isRecording,
            // Any other shift-related fields
            ...device
          }) => device
        );

        const shiftData = devicesWithParsedDates.reduce((acc, device) => {
          acc[device.deviceId] = {
            shiftStatus: device.shiftStatus,
            lastShiftTime: device.lastShiftTime,
            timeInShift: device.timeInShift,
            isRecording: device.isRecording,
          };
          return acc;
        }, {});

        setDevices((prevDevices) => {
          if (!devicesAreEqual(prevDevices, devicesWithoutShiftData)) {
            return devicesWithoutShiftData;
          }
          return prevDevices;
        });

        // Always update shift data, since it's high-frequency
        setDeviceShifts(shiftData);

        if (!deviceFilter && pingedDevices.length > 0) {
          const initialDevice = pingedDevices.find((device) => device.isStreamActive && device.isAlertsActive);
          const initialDeviceId = initialDevice ? initialDevice.deviceId : pingedDevices[0].deviceId;
          setDeviceFilter(initialDeviceId);
          localStorage.setItem('deviceFilter', initialDeviceId);
          setIsLoading(false);
        }
      } catch (error) {
        console.error('Error fetching devices:', error);
        setIsLoading(false);
      }
    }, 1000),
    [isOnline, userId, selectedOrg, deviceFilter, userRole]
  );

  useEffect(() => {
    debouncedFetchDevices();
    const interval = setInterval(debouncedFetchDevices, 30000);
    return () => {
      clearInterval(interval);
      debouncedFetchDevices.cancel();
    };
  }, [debouncedFetchDevices]);

  useEffect(() => {
    if (deviceFilter) {
      localStorage.setItem('deviceFilter', deviceFilter);
    }
  }, [deviceFilter]);

  const updateDeviceAssignment = async (deviceId, assignedTo) => {
    const updatedDevices = devices.map((device) => (device.deviceId === deviceId ? { ...device, assignedTo } : device));
    setDevices(updatedDevices);
    await fetchDeviceConfigs(userId, { assignedTo }, userId);
  };

  const contextValue = useMemo(
    () => ({
      devices,
      deviceShifts,
      deviceFilter,
      setDeviceFilter,
      updateDeviceAssignment,
      selectedOrg,
      setSelectedOrg,
      isLoading,
    }),
    [devices, deviceShifts, deviceFilter, updateDeviceAssignment, selectedOrg, isLoading]
  );

  return <DeviceFilterContext.Provider value={contextValue}>{children}</DeviceFilterContext.Provider>;
};

export const useDeviceFilter = () => useContext(DeviceFilterContext);
