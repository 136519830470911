// src/components/AnnouncementPopup.js
import React, { useState, useEffect, useRef } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes, faEnvelope, faFileExcel, faHistory, faLink } from '@fortawesome/free-solid-svg-icons';
import '../styles/AnnouncementPopup.css';
import { faArrowRight, faHandPeace } from '@fortawesome/free-solid-svg-icons';
import { isWithinInterval, isPast, addDays, format } from 'date-fns';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useDeviceFilter } from '../contexts/DeviceFilterContext';

const AnnouncementPopup = ({ announcement, onClose }) => {
  const popupRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (popupRef.current && !popupRef.current.contains(event.target)) {
        onClose();
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [onClose]);

  return (
    <div className="announcement-popup-overlay">
      <div className="announcement-popup-content" ref={popupRef}>
        <button className="announcement-popup-close" onClick={onClose}>
          <FontAwesomeIcon icon={faTimes} />
        </button>
        <div className="announcement-popup-icon">
          <FontAwesomeIcon icon={announcement.icon} />
        </div>
        <h2>{announcement.text}</h2>
        <div className="announcement-popup-text" dangerouslySetInnerHTML={{ __html: announcement.subtext }} />
      </div>
    </div>
  );
};

// Global variables for announcement
const TODAY = new Date('2024-10-22');
const WEEK_LENGTH = 7 * 24 * 60 * 60 * 1000;
const TWO_WEEKS_LATER = new Date(TODAY.getTime() + WEEK_LENGTH * 2);
const DEVICE_UPDATE_DATE = new Date('2024-10-30T10:00:00-07:00');

const formatLocalDateTime = (date) => {
  return format(date, "EEEE, MMMM d 'at' h:mm a");
};

const ANNOUNCEMENTS = [
  {
    text: 'Daily activity reports in your inbox',
    dateRange: [TODAY, TWO_WEEKS_LATER],
    link: '/settings#Reports',
    icon: faEnvelope,
    isFeature: true,
    description: 'Receive daily summaries of all activities directly in your email inbox.',
  },
  {
    text: 'Export personnel and site activity to Excel',
    dateRange: [TODAY, TWO_WEEKS_LATER],
    link: '/settings#Reports',
    icon: faFileExcel,
    isFeature: true,
    description: 'Easily export detailed personnel and site activity data to Excel for further analysis.',
  },
  {
    text: 'Audit trail for every video',
    dateRange: [TODAY, TWO_WEEKS_LATER],
    link: '/videos',
    icon: faHistory,
    isFeature: true,
    description: 'View a comprehensive audit trail for each video, tracking all interactions and changes.',
  },
  {
    text: 'Shareable links for footage',
    dateRange: [new Date('2024-11-14'), new Date('2024-11-28')],
    link: '/videos',
    icon: faLink,
    isFeature: true,
    description: 'Create and share secure links to specific video footage with authorized personnel.',
  },
  {
    text: 'Device recording controls changing 10/30',
    dateRange: [new Date('2024-10-28'), new Date(new Date('2024-10-28').getTime() + WEEK_LENGTH)],
    link: 'two-finger',
    icon: faHandPeace,
    isFeature: false,
    subtext: () => {
      const localUpdateDateTime = formatLocalDateTime(DEVICE_UPDATE_DATE);
      return `Your personnel ${isPast(DEVICE_UPDATE_DATE) ? 'received' : 'will receive'} a device update on ${localUpdateDateTime} with a change to how they record:<ul><li>Press and hold with 1 finger to start or end shift (no change).</li><li>Press and hold with 2 fingers to start or end recording.</li></ul>`;
    },
    excludedUserIds: ['auth0|669fed96e6d5fdc532aedf04'],
    description: 'New two-finger gesture for starting and ending recordings on devices.',
  },
];

function Announcements({ userId, isOverwatch }) {
  const navigate = useNavigate();
  const location = useLocation();
  const [currentAnnouncement, setCurrentAnnouncement] = useState(null);
  const [showPopup, setShowPopup] = useState(false);
  const [showNotificationCenter, setShowNotificationCenter] = useState(false);
  const { selectedOrg, setSelectedOrg, setDeviceFilter } = useDeviceFilter();
  const notificationCenterRef = useRef(null);
  const bannerRef = useRef(null);
  const getEffectiveUserId = () => (isOverwatch ? selectedOrg : userId);
  const [activeAnnouncementCount, setActiveAnnouncementCount] = useState(0);

  const isAnnouncementActive = (announcement) => {
    const now = new Date();
    return isWithinInterval(now, { start: announcement.dateRange[0], end: announcement.dateRange[1] });
  };

  useEffect(() => {
    const now = new Date();

    const validAnnouncementsCount = ANNOUNCEMENTS.filter(
      (announcement) =>
        isWithinInterval(now, { start: announcement.dateRange[0], end: announcement.dateRange[1] }) &&
        (!announcement.excludedUserIds || !announcement.excludedUserIds.includes(getEffectiveUserId()))
    ).length;

    setActiveAnnouncementCount(validAnnouncementsCount);
  }, [getEffectiveUserId]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        showNotificationCenter &&
        notificationCenterRef.current &&
        !notificationCenterRef.current.contains(event.target) &&
        !bannerRef.current.contains(event.target)
      ) {
        setShowNotificationCenter(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [showNotificationCenter]);

  if (activeAnnouncementCount === 0 || location.pathname === '/videos') {
    return null;
  }

  const handleBannerClick = (e) => {
    e.preventDefault();
    setShowNotificationCenter(!showNotificationCenter);
  };

  const handleArrowClick = (e) => {
    e.stopPropagation(); // Prevent banner click event
    if (currentAnnouncement) {
      handleNotificationItemClick(currentAnnouncement);
    }
  };

  const handleNotificationItemClick = (announcement) => {
    setShowNotificationCenter(false);
    if (announcement.link.startsWith('/')) {
      navigate(announcement.link);
    } else {
      setCurrentAnnouncement(announcement);
      setShowPopup(true);
    }
  };

  const handleOverlayClick = () => {
    setShowNotificationCenter(false);
    setShowPopup(false);
  };

  const sortedAnnouncements = ANNOUNCEMENTS.sort((a, b) => b.dateRange[0] - a.dateRange[0]);

  return (
    <>
      {(showNotificationCenter || showPopup) && (
        <div className="announcement-overlay" onClick={handleOverlayClick}></div>
      )}
      <div className="floating-announcement-banner" ref={bannerRef}>
        <div className="announcement-banner" onClick={handleBannerClick}>
          What's new in Plix?
          <div className="announcement-count">{activeAnnouncementCount}</div>
        </div>
        {showNotificationCenter && (
          <div className="notification-center" ref={notificationCenterRef}>
            <div className="notification-center-content">
              {sortedAnnouncements.map((announcement, index) => {
                const isActive = isAnnouncementActive(announcement);
                return (
                  <div
                    key={index}
                    className={`notification-item ${isActive ? 'active' : ''}`}
                    onClick={() => handleNotificationItemClick(announcement)}
                  >
                    <div className="notification-item-left">
                      <FontAwesomeIcon icon={announcement.icon} className="notification-icon" />
                      <span className="notification-date">{format(announcement.dateRange[0], 'MM/dd/yy')}</span>
                    </div>
                    <div className="notification-content">
                      <div className="notification-text">{announcement.text}</div>
                      <div className="notification-description">{announcement.description}</div>
                    </div>
                    <FontAwesomeIcon icon={faArrowRight} className="notification-arrow" />
                  </div>
                );
              })}
            </div>
          </div>
        )}
      </div>
      {showPopup && (
        <AnnouncementPopup
          announcement={{
            ...currentAnnouncement,
            subtext:
              typeof currentAnnouncement.subtext === 'function'
                ? currentAnnouncement.subtext()
                : currentAnnouncement.subtext,
          }}
          onClose={() => setShowPopup(false)}
        />
      )}
    </>
  );
}

export default Announcements;
