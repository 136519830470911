import { CosmosClient } from '@azure/cosmos';
import Buffer from 'buffer'; // If needed for Buffer

// Initialize Cosmos DB client
const client = new CosmosClient({
  endpoint: 'https://plixeventstorage.documents.azure.com:443/',
  key: process.env.REACT_APP_AZURE_COSMOS_KEY,
});

// Database and container references
const AlertsDB = 'Alerts'; // Database ID for alerts
const alertsContainerId = 'StateMachineAlerts'; // Container ID for alerts
const VideosDB = 'Videos'; // Database ID for videos
const videosContainerId = 'Videos'; // Container ID for videos
const ShiftsDB = 'Shifts'; // Database ID for shifts
const shiftsContainerId = 'Shifts'; // Container ID for shifts

// Function to update an alert when thumbs up is clicked
export async function updateAlertOnThumbsUp(alertId, deviceId) {
  try {
    // Access the alerts database and container
    const alertsDatabase = client.database(AlertsDB);
    const alertsContainer = alertsDatabase.container(alertsContainerId);

    // Read the alert item
    const { resource: alert } = await alertsContainer.item(alertId, deviceId).read();

    // Update the alert fields
    if (alert) {
      if (alert.IsCustomerVisible) {
        // If the alert is already customer visible, only update ClassificationResult
        if (!alert.ClassificationResult) {
          alert.ClassificationResult = {};
        }
        alert.ClassificationResult['true'] = 'Escalation';

        // Update the alert in the database
        const { resource: updatedAlert } = await alertsContainer.item(alertId, deviceId).replace(alert);

        // update video
        if (alert.VideoFileName) {
          await updateVideoDisplayStatus(alert.VideoFileName, deviceId);
        }

        console.log(`Alert ${alertId} updated successfully with ClassificationResult.`);
        return updatedAlert;
      } else {
        // Proceed with the original behavior
        alert.Label = 'Escalation';
        alert.IsDeleted = false;
        if (!alert.ClassificationResult) {
          alert.ClassificationResult = {};
        }
        alert.ClassificationResult['true'] = 'Escalation';

        // Update the alert in the database
        const { resource: updatedAlert } = await alertsContainer.item(alertId, deviceId).replace(alert);

        // Now update the corresponding video
        if (alert.VideoFileName) {
          await updateVideoDisplayStatus(alert.VideoFileName, deviceId);
        }

        // Update the shift with the escalation event
        await addEscalationToShift(alert);

        console.log(`Alert ${alertId} updated successfully with full escalation process.`);
        return updatedAlert;
      }
    } else {
      console.error(`Alert ${alertId} not found.`);
      return null; // Return null if alert not found
    }
  } catch (error) {
    console.error(`Error updating alert ${alertId}:`, error);
    return null; // Return null in case of error
  }
}

// Function to update an alert when thumbs down is clicked
export async function updateAlertOnThumbsDown(alertId, deviceId) {
  try {
    // Access the alerts database and container
    const alertsDatabase = client.database(AlertsDB);
    const alertsContainer = alertsDatabase.container(alertsContainerId);

    // Read the alert item
    const { resource: alert } = await alertsContainer.item(alertId, deviceId).read();

    // Update the alert fields
    if (alert) {
      if (!alert.IsCustomerVisible) {
        // If the alert is not customer visible, only update ClassificationResult
        if (!alert.ClassificationResult) {
          alert.ClassificationResult = {};
        }
        alert.ClassificationResult['true'] = 'Not Escalation';

        // Update the alert in the database
        const { resource: updatedAlert } = await alertsContainer.item(alertId, deviceId).replace(alert);
        if (alert.VideoFileName) {
          await updateVideoDisplayStatusForThumbsDown(alert.VideoFileName, deviceId);
        }

        console.log(`Alert ${alertId} updated successfully with ClassificationResult.`);
        return updatedAlert;
      } else {
        // Proceed with the original behavior
        alert.IsDeleted = true;
        if (!alert.ClassificationResult) {
          alert.ClassificationResult = {};
        }
        alert.ClassificationResult['true'] = 'Not Escalation';

        // Update the alert in the database
        const { resource: updatedAlert } = await alertsContainer.item(alertId, deviceId).replace(alert);

        // Now update the corresponding video
        if (alert.VideoFileName) {
          await updateVideoDisplayStatusForThumbsDown(alert.VideoFileName, deviceId);
        }

        // Remove the escalation from the shift
        await removeEscalationFromShift(alert);

        console.log(`Alert ${alertId} updated successfully for thumbs down.`);
        return updatedAlert;
      }
    } else {
      console.error(`Alert ${alertId} not found.`);
      return null; // Return null if alert not found
    }
  } catch (error) {
    console.error(`Error updating alert ${alertId} for thumbs down:`, error);
    return null; // Return null in case of error
  }
}

// Function to update the video's displayStatus to true and update ClassificationResult
async function updateVideoDisplayStatus(videoFileName, deviceId) {
  try {
    // Access the videos database and container
    const videosDatabase = client.database(VideosDB);
    const videosContainer = videosDatabase.container(videosContainerId);

    // Query for the video with matching FileName
    const querySpec = {
      query: 'SELECT * FROM c WHERE c.FileName = @fileName',
      parameters: [
        {
          name: '@fileName',
          value: videoFileName,
        },
      ],
    };

    const { resources: videos } = await videosContainer.items.query(querySpec).fetchAll();

    if (videos.length > 0) {
      const video = videos[0];
      video.displayStatus = true;

      // Update ClassificationResult['true'] to 'Escalation'
      if (!video.ClassificationResult) {
        video.ClassificationResult = {};
      }
      video.ClassificationResult['true'] = 'Escalation';

      // Update the video in the database
      await videosContainer.item(video.id, deviceId).replace(video);

      console.log(`Video ${videoFileName} updated successfully.`);
    } else {
      console.error(`Video with FileName ${videoFileName} not found.`);
    }
  } catch (error) {
    console.error(`Error updating video ${videoFileName}:`, error);
  }
}

// Function to update the video's displayStatus to false
async function updateVideoDisplayStatusForThumbsDown(videoFileName, deviceId) {
  try {
    // Access the videos database and container
    const videosDatabase = client.database(VideosDB);
    const videosContainer = videosDatabase.container(videosContainerId);

    // Query to find the video by FileName
    const querySpec = {
      query: 'SELECT * FROM c WHERE c.FileName = @videoFileName AND c.deviceId = @deviceId',
      parameters: [
        { name: '@videoFileName', value: videoFileName },
        { name: '@deviceId', value: deviceId },
      ],
    };

    const { resources: videos } = await videosContainer.items.query(querySpec).fetchAll();

    if (videos.length > 0) {
      const video = videos[0];
      video.displayStatus = false;
      // Update ClassificationResult['true'] to 'Escalation'
      if (!video.ClassificationResult) {
        video.ClassificationResult = {};
      }
      video.ClassificationResult['true'] = 'Not Escalation';

      // Update the video in the database
      await videosContainer.item(video.id, video.AccountId).replace(video);
      console.log(`Video ${videoFileName} updated successfully.`);
      return video;
    } else {
      console.error(`Video ${videoFileName} not found.`);
      return null; // Return null if video not found
    }
  } catch (error) {
    console.error(`Error updating video ${videoFileName}:`, error);
    return null; // Return null in case of error
  }
}

// Function to add escalation event to the corresponding shift
async function addEscalationToShift(alert) {
  try {
    // Access the shifts database and container
    const shiftsDatabase = client.database(ShiftsDB);
    const shiftsContainer = shiftsDatabase.container(shiftsContainerId);

    const orgUserId = alert.userId; // orgUserId in the shift is the same as alert.userId
    const assignedTo = alert.AssignedTo;
    const deviceId = alert.DeviceId;
    const escalationTimestamp = new Date(alert.EscalationStartTimestamp);

    // Query for shifts associated with the orgUserId, deviceId, and assignedTo
    const querySpec = {
      query: `
        SELECT *
        FROM c
        WHERE c.orgUserId = @orgUserId
        AND c.deviceId = @deviceId
      `,
      parameters: [
        { name: '@orgUserId', value: orgUserId },
        { name: '@deviceId', value: deviceId },
      ],
    };

    const { resources: shifts } = await shiftsContainer.items.query(querySpec).fetchAll();

    // Find the corresponding shift where escalationTimestamp falls within startTime and endTime
    let targetShift = null;
    for (const shift of shifts) {
      const startTime = new Date(shift.startTime);
      const endTime = shift.endTime ? new Date(shift.endTime) : new Date();

      if (escalationTimestamp >= startTime && escalationTimestamp <= endTime) {
        targetShift = shift;
        break;
      }
    }

    if (targetShift) {
      // Check if escalation event already exists
      const existingEvent = (targetShift.events || []).find(
        (event) => event.type === 'escalation' && event.alertId === alert.id
      );

      if (existingEvent) {
        console.log(`Escalation event already exists in shift ${targetShift.id}.`);
        return;
      }

      // Fetch location data
      const locationData = await fetchLocation(deviceId, alert.EscalationStartTimestamp);

      let location = null;
      if (locationData) {
        const { latitude, longitude } = getCoordinatesFromHash(locationData.location);

        // Reverse geocode using Google Maps Geocoding API
        const address = await reverseGeocode(latitude, longitude);

        location = {
          latitude,
          longitude,
          address: address || 'Unavailable',
        };
      } else {
        // Placeholder if location not found
        location = {
          latitude: 0,
          longitude: 0,
          address: 'Unknown',
        };
      }

      // Create escalation event
      const escalationEvent = {
        type: 'escalation',
        timestamp: alert.EscalationStartTimestamp,
        alertId: alert.id,
        location: location,
      };

      // Insert the escalation event at the correct chronological position
      const events = targetShift.events || [];
      events.push(escalationEvent);
      // Sort events by timestamp
      events.sort((a, b) => new Date(a.timestamp) - new Date(b.timestamp));
      targetShift.events = events;

      // Update the shift in the database
      // Note: Ensure the correct partition key is used
      await shiftsContainer.item(targetShift.id, orgUserId).replace(targetShift);

      console.log(`Escalation event added to shift ${targetShift.id}.`);
    } else {
      console.error(
        `No matching shift found for orgUserId ${orgUserId}, assignedTo ${assignedTo}, device ${deviceId} at time ${alert.EscalationStartTimestamp}.`
      );
    }
  } catch (error) {
    console.error('Error adding escalation to shift:', error);
  }
}
// Function to remove escalation event from the corresponding shift
async function removeEscalationFromShift(alert) {
  try {
    const { userId, EscalationStartTimestamp, id: alertId } = alert;

    // Access the shifts database and container
    const shiftsDatabase = client.database(ShiftsDB);
    const shiftsContainer = shiftsDatabase.container(shiftsContainerId);

    // Query to find the shift corresponding to the userId and timestamp
    const querySpec = {
      query:
        'SELECT * FROM c WHERE c.orgUserId = @userId AND @timestamp >= c.startTime AND (@timestamp <= c.endTime OR c.inProgress = true)',
      parameters: [
        { name: '@userId', value: userId },
        { name: '@timestamp', value: EscalationStartTimestamp },
      ],
    };

    const { resources: shifts } = await shiftsContainer.items.query(querySpec).fetchAll();

    if (shifts.length > 0) {
      const shift = shifts[0];

      // Remove the escalation event matching the alertId
      shift.events = shift.events.filter((event) => !(event.type === 'escalation' && event.alertId === alertId));

      // Update the shift in the database
      await shiftsContainer.item(shift.id, shift.orgUserId).replace(shift);
      console.log(`Escalation event removed from shift ${shift.id}`);
      return shift;
    } else {
      console.error(`Shift not found for userId ${userId} at timestamp ${EscalationStartTimestamp}`);
      return null; // Return null if shift not found
    }
  } catch (error) {
    console.error('Error removing escalation from shift:', error);
    return null; // Return null in case of error
  }
}
// Function to reverse geocode using Google Maps Geocoding API
async function reverseGeocode(lat, lon) {
  try {
    const googleMapsKey = process.env.REACT_APP_GOOGLE_MAPS_KEY;
    const url = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lon}&key=${googleMapsKey}`;

    const response = await fetch(url);
    const data = await response.json();

    if (data.status === 'OK' && data.results.length > 0) {
      const result = data.results[0];

      // Extract the most relevant components
      const streetNumber =
        result.address_components.find((component) => component.types.includes('street_number'))?.long_name || '';
      const route = result.address_components.find((component) => component.types.includes('route'))?.long_name || '';
      const locality =
        result.address_components.find((component) => component.types.includes('locality'))?.long_name || '';
      const administrativeArea =
        result.address_components.find((component) => component.types.includes('administrative_area_level_1'))
          ?.short_name || '';
      const country =
        result.address_components.find((component) => component.types.includes('country'))?.long_name || '';
      const postalCode =
        result.address_components.find((component) => component.types.includes('postal_code'))?.long_name || '';

      // Construct a formatted address
      const formattedAddress =
        `${streetNumber} ${route}, ${locality}, ${administrativeArea} ${postalCode}, ${country}`.trim();

      return formattedAddress;
    } else {
      console.warn('No results found or invalid status in geocoding response');
      return `Unknown Street Address (${lat}, ${lon})`;
    }
  } catch (error) {
    console.error('Error reverse geocoding:', error);
    return 'Unknown Street Address';
  }
}

// Function to fetch location data from TimescaleDB
async function fetchLocation(deviceId, timestamp) {
  try {
    const timescaleBaseUrl = process.env.REACT_APP_TIMESCALE_BASE_URL;
    const timescaleApiKey = process.env.REACT_APP_TIMESCALE_API_KEY;
    const time = new Date(timestamp);
    const startTime = new Date(time.getTime() - 2.5 * 60 * 1000).toISOString();
    const endTime = new Date(time.getTime() + 2.5 * 60 * 1000).toISOString();

    const params = new URLSearchParams({
      device: `eq.${deviceId}`,
      time: `gte.${startTime}`,
      time: `lte.${endTime}`,
      order: 'time.desc',
      limit: '1',
    });

    const url = `${timescaleBaseUrl}/device_location?${params.toString()}`;

    const headers = {
      apikey: timescaleApiKey,
      Authorization: `Bearer ${timescaleApiKey}`,
      'Content-Type': 'application/json',
    };

    const response = await fetch(url, { headers });
    if (!response.ok) {
      throw new Error(`HTTP error ${response.status}`);
    }
    const locations = await response.json();
    if (!locations || locations.length === 0) {
      return null;
    }
    return locations[0];
  } catch (error) {
    console.error('Error fetching location:', error);
    return null;
  }
}

// Function to convert location hash to longitude and latitude
function getCoordinatesFromHash(locationHash) {
  // Remove the first 18 characters (SRID info)
  const coordsHex = locationHash.substring(18);

  // Split the remaining hex string into two 16-character parts
  const lonHex = coordsHex.substring(0, 16);
  const latHex = coordsHex.substring(16, 32);

  // Convert hex to float64
  const longitude = hexToFloat64(lonHex);
  const latitude = hexToFloat64(latHex);

  return { latitude, longitude };
}

// Helper function to convert hex string to float64
function hexToFloat64(hexStr) {
  // If the hex string is shorter than 16 characters, pad it with zeros
  hexStr = hexStr.padStart(16, '0');

  // Convert the hex string to an array of bytes
  const bytes = new Uint8Array(
    hexStr
      .match(/.{1,2}/g)
      .map((byte) => parseInt(byte, 16))
      .reverse() // Reverse the array for little-endian format
  );

  // Create a DataView for the bytes buffer
  const dataView = new DataView(bytes.buffer);

  // Read the Float64 value (little-endian)
  return dataView.getFloat64(0, false); // Use false for big-endian
}
