import React, { useRef, useCallback, useMemo, useState, useEffect } from 'react';
import { format } from 'date-fns';
import '../styles/AlertPopup.css';
import VideoPlayer from './VideoPlayer';
import Transcript from './Transcript';
import { stringToColor } from '../utils/utilsUI';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import { fetchVideoByFileName } from '../api/beaverApi';

const AlertPopup = React.memo(({ alert, userId }) => {
  const videoPlayerRef = useRef(null);
  const playerStateRef = useRef({ currentTime: 0, isPlaying: false });
  const [videoObject, setVideoObject] = useState(null);

  const formatAlertTimestamp = useCallback((timestamp) => {
    return format(new Date(timestamp), "MMM d, yyyy 'at' h:mm a");
  }, []);

  const backgroundColor = useMemo(() => (alert ? stringToColor(alert.AssignedTo) : ''), [alert]);

  const handlePlayerUpdate = useCallback(({ currentTime, isPlaying }) => {
    console.log('Time updated! ', { currentTime, isPlaying });
    playerStateRef.current = { currentTime, isPlaying };
  }, []);

  const handleTranscriptClick = useCallback((timestamp) => {
    if (videoPlayerRef.current && videoPlayerRef.current.plyr) {
      videoPlayerRef.current.plyr.currentTime = timestamp;
    }
  }, []);

  const getPlayerState = useCallback(() => playerStateRef.current, []);

  useEffect(() => {
    const fetchVideo = async () => {
      if (alert && alert.VideoFileName) {
        try {
          const video = await fetchVideoByFileName(alert.VideoFileName);
          setVideoObject(video);
        } catch (error) {
          console.error('Error fetching video:', error);
        }
      }
    };

    fetchVideo();
  }, [alert]);

  if (!alert) {
    return null; // Return null if there's no alert to display
  }

  return (
    <div className="alert-popup">
      <div className="alert-header">
        <div className="alert-info">
          <span className="alert-assigned-to" style={{ backgroundColor, color: '#333' }}>
            {alert.AssignedTo}
          </span>
          <p className="alert-timestamp">{formatAlertTimestamp(alert.Timestamp)}</p>
        </div>
      </div>
      <div className="alert-video">
        {alert.VideoFileName && videoObject && videoObject.TranscriptVTTPath ? (
          <VideoPlayer
            ref={videoPlayerRef}
            videoFileName={alert.VideoFileName}
            markers={[{ time: alert.RelativeTimestamp, label: alert.Label }]}
            token={process.env.REACT_APP_BLOB_SAS_TOKEN}
            onPlayerUpdate={handlePlayerUpdate}
          />
        ) : (
          <div className="video-placeholder">
            <AccessTimeIcon className="placeholder-icon" />
            <p>Video not currently available</p>
          </div>
        )}
      </div>
      <div className="alert-body">
        <Transcript
          alert={alert}
          getPlayerState={getPlayerState}
          onTranscriptClick={handleTranscriptClick}
          userId={userId}
        />
      </div>
    </div>
  );
});

export default AlertPopup;
