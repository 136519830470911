// VideoPopup.js

import React, { useRef, useCallback } from 'react';
import { format } from 'date-fns';
import '../styles/AlertPopup.css';
import VideoPlayer from './VideoPlayer';
import { stringToColor } from '../utils/utilsUI';

const VideoPopup = React.memo(({ video }) => {
  const videoPlayerRef = useRef(null);

  const formatVideoTimestamp = useCallback((timestamp) => {
    return format(new Date(timestamp), "MMM d, yyyy 'at' h:mm a");
  }, []);

  const backgroundColor = stringToColor(video.AssignedTo || 'Unknown');

  return (
    <div className="alert-popup video-popup">
      <div className="alert-header">
        <div className="alert-info">
          <span className="alert-assigned-to" style={{ backgroundColor, color: '#333' }}>
            {video.AssignedTo || 'Unknown'}
          </span>
          <p className="alert-timestamp">{formatVideoTimestamp(video.TimeRecorded || video.TimeUploaded)}</p>
        </div>
      </div>
      <div className="alert-video">
        <VideoPlayer
          ref={videoPlayerRef}
          videoFileName={video.FileName}
          token={video.BlobSAS}
          transcriptUrl={video.TranscriptVTTPath}
          markers={[]} // Pass an empty array if there are no markers
        />
      </div>
    </div>
  );
});

export default VideoPopup;
