import RealTimeMap from './RealTimeMap';
import Dashboard from './Dashboard';
import NewDashboard from './NewDashboard/NewDashboard';
import BatteryMetrics from './BatteryMetrics';
import VideoManagement from './VideoManagement';
import Loading from './Loading';
import Configuration from './Configuration'; // Import the new configuration page
import { BrowserRouter as Router, Routes, Route, useLocation, Navigate } from 'react-router-dom';
import Sidebar from './Sidebar';
import { useEffect, useState } from 'react';
import { AlertsProvider } from '../contexts/AlertsContext';
import { DeviceFilterProvider } from '../contexts/DeviceFilterContext';
import DeviceManagement from './DeviceManagement';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer } from 'react-toastify';
import ErrorBoundary from './ErrorBoundary';
import { useAuth0 } from '@auth0/auth0-react';
import { fetchUserRoles } from '../api/beaverApi';
import { fetchUserMapping, getUserRoleFromToken, fetchConfig, fetchRetentionPeriod } from '../api/beaverApi';
import Announcements from './AnnouncementPopup';
const REQUIRED_AUTH_VERSION = '1.0'; // Increment this when making breaking auth changes

function App({ isAuthenticated, user, logout }) {
  const { getAccessTokenSilently, isLoading } = useAuth0();
  // const location = useLocation();
  const [config, setConfig] = useState(null);
  const [userSub, setUserSub] = useState(user.sub);
  const [featureMode, setFeatureMode] = useState('prod'); // prod | dev | demo
  const [error, setError] = useState(null); // State to manage errors
  const [retentionPeriodDays, setRetentionPeriodDays] = useState(30);
  const [showOutageAlert, setShowOutageAlert] = useState(false);
  const [userRole, setUserRole] = useState(null);

  useEffect(() => {
    const getAndStoreAccessToken = async () => {
      if (!isAuthenticated) return;

      try {
        localStorage.setItem('token_fetch_in_progress', Date.now().toString());

        const accessToken = await getAccessTokenSilently({
          authorizationParams: {
            audience: process.env.REACT_APP_AUTH0_AUDIENCE,
            scope: 'openid profile email',
          },
        });
        if (accessToken) {
          localStorage.setItem('access_token', accessToken);
          localStorage.setItem('auth_version', REQUIRED_AUTH_VERSION);
        }
        localStorage.removeItem('token_fetch_in_progress');
      } catch (error) {
        console.error('Token fetch failed:', error);
        if (error.error === 'invalid_grant' || error.response?.status === 400) {
          console.error('Invalid grant or 400 error - session may be expired');
        }
        localStorage.clear(); // Clear all auth state
        logout({ returnTo: window.location.origin });
      }
    };

    // Always try to get fresh token on mount
    if (!isLoading && isAuthenticated) {
      getAndStoreAccessToken();
    }
  }, [isAuthenticated, getAccessTokenSilently, isLoading, logout]);

  useEffect(() => {
    const getUserRole = () => {
      if (isAuthenticated && user) {
        try {
          const roleName = getUserRoleFromToken(user);
          setUserRole(roleName);
        } catch (error) {
          console.error('Error getting user role:', error);
        }
      }
    };

    getUserRole();
  }, [isAuthenticated, user]);

  useEffect(() => {
    let isMounted = true;

    async function fetchUserMappingAndConfig() {
      try {
        if (!isAuthenticated || isLoading) return;

        const mapping = await fetchUserMapping(getAccessTokenSilently);
        if (!isMounted) return;

        const { mapped } = mapping;
        setUserSub(mapped.userId);
        setFeatureMode(mapped.mode);

        // Parallel fetch of config and retention period
        const [configFromDB, retentionPeriodDays] = await Promise.all([
          fetchConfig(mapped.userId),
          fetchRetentionPeriod(mapped.userId),
        ]);

        setConfig(configFromDB);
        setRetentionPeriodDays(retentionPeriodDays);
      } catch (error) {
        if (!isMounted) return;
        console.error('Failed to fetch user mapping or config:', error);
        setError(error.message || 'Failed to load user configuration. Please try again later.');
      }
    }

    if (user?.sub && isAuthenticated && !isLoading) {
      fetchUserMappingAndConfig();
    }

    return () => {
      isMounted = false;
    };
  }, [user?.sub, isAuthenticated, isLoading, getAccessTokenSilently]);

  if (error) {
    return (
      <div className="error-container">
        <p className="error-message">{error}</p>
      </div>
    );
  }

  // if (location.pathname.startsWith('/sharedvideo')) {
  //   return (
  //     <Routes>
  //       <Route path="/sharedvideo/:sharedVideoId" element={<SharedVideo />} />
  //     </Routes>
  //   );
  // }

  if (!config) {
    return <Loading />; // early return until config is loaded
  }

  if (isLoading) {
    return <Loading />;
  }

  if (!isAuthenticated) {
    // Redirect to login or show login button
    return <div>Please log in</div>;
  }

  // useEffect(() => {
  //   const viewport = document.querySelector('meta[name=viewport]');
  //   viewport.setAttribute('content', 'width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=0');
  // }, []);

  return (
    <ErrorBoundary>
      <AlertsProvider user={user}>
        <Router>
          <div style={{ display: 'flex', overflow: 'clip' }}>
            <DeviceFilterProvider userId={userSub} userRole={userRole}>
              <Sidebar userName={user.name} userEmail={user.email} featureMode={featureMode} userRole={userRole} />
              <div className="main-content-area">
                <Announcements
                  userId={userSub}
                  isOverwatch={featureMode === 'dev' && user.name.startsWith('overwatch')}
                />
                <Routes>
                  <Route
                    path="/"
                    element={
                      <RealTimeMap
                        isAuthenticated={isAuthenticated}
                        logout={logout}
                        userId={userSub}
                        config={config}
                        setConfig={setConfig}
                        userRole={userRole}
                        retentionPeriodDays={retentionPeriodDays}
                      />
                    }
                  />
                  <Route path="olddashboard" element={<Dashboard userId={userSub} featureMode={featureMode} />} />
                  <Route
                    path="olddashboard/:escalation"
                    element={<Dashboard userId={userSub} featureMode={featureMode} />}
                  />
                  <Route
                    path="settings"
                    element={
                      userRole === 'guard' ? (
                        <Navigate to="/" replace />
                      ) : (
                        <Configuration
                          config={config}
                          setConfig={setConfig}
                          userId={userSub}
                          featureMode={featureMode}
                          retentionPeriodDays={retentionPeriodDays}
                          isOverwatch={featureMode === 'dev' && user.name.startsWith('overwatch')}
                        />
                      )
                    }
                  />
                  <Route
                    path="videos"
                    element={
                      <VideoManagement
                        isAuthenticated={isAuthenticated}
                        logout={logout}
                        userId={userSub}
                        retentionPeriodDays={retentionPeriodDays}
                        userName={user.name}
                      />
                    }
                  />
                  <Route
                    path="dashboard"
                    element={
                      <NewDashboard userId={userSub} userRole={userRole} retentionPeriodDays={retentionPeriodDays} />
                    }
                  />
                  {featureMode === 'dev' && (
                    <Route path="batterymetrics" element={<BatteryMetrics userId={userSub} />} />
                  )}
                  <Route
                    path="configuration"
                    element={
                      userRole === 'guard' ? (
                        <Navigate to="/" replace />
                      ) : (
                        <Configuration
                          config={config}
                          setConfig={setConfig}
                          userId={userSub}
                          retentionPeriodDays={retentionPeriodDays}
                          isOverwatch={featureMode === 'dev' && user.name.startsWith('overwatch')}
                        />
                      )
                    }
                  />
                  {featureMode === 'dev' && (
                    <Route path="device-management" element={<DeviceManagement userId={userSub} />} />
                  )}
                </Routes>
              </div>
            </DeviceFilterProvider>
          </div>
          {/* <AppAlertPopup open={showOutageAlert} onClose={handleCloseOutageAlert} /> */}
          <ToastContainer />
        </Router>
      </AlertsProvider>
    </ErrorBoundary>
  );
}

export default App;
