import * as React from 'react';
import Timeline from '@mui/lab/Timeline';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineDot from '@mui/lab/TimelineDot';
import TimelineOppositeContent from '@mui/lab/TimelineOppositeContent';
import Typography from '@mui/material/Typography';
import '../../../../../../../styles/NewDashboard/ShiftReview/ShiftCard/ShiftExpanded/CustomizedTimeline.css';
import { formatDuration } from '../../../../../../../utils/timeUtils';
import { useState, useEffect } from 'react';
import BatteryIcon from './BatteryIcon'; // Add this import
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faVideo, faMapMarkerAlt } from '@fortawesome/free-solid-svg-icons';
import { fetchAlertFromApi } from '../../../../../../../api/beaverApi';

const formatShortDuration = (duration) => {
  const seconds = Math.floor(duration / 1000);
  if (seconds < 60) {
    return `${seconds}s`;
  }
  const minutes = Math.floor(seconds / 60);
  if (minutes < 60) {
    return `${minutes}m`;
  }
  const hours = Math.floor(minutes / 60);
  const remainingMinutes = minutes % 60;
  return `${hours}h ${remainingMinutes}m`;
};

const truncateGeofenceId = (geofenceId, geofences) => {
  // console.log("geofences: ", geofences)
  if (geofences && Array.isArray(geofences)) {
    const geofence = geofences.find((g) => g.id === geofenceId);
    if (geofence && geofence.nickname) {
      return geofence.nickname;
    }
  }

  if (geofenceId.length <= 30) {
    return geofenceId;
  }
  const parts = geofenceId.split(',');
  if (parts[0].match(/\d/)) {
    return `${parts[0]}`.trim();
  }
  return parts[0].trim();
};

const getEventIcon = (event, index, events) => {
  switch (event.type) {
    case 'recordingVideo':
      return <FontAwesomeIcon icon={faVideo} />;
    case 'locationEnter':
      return <FontAwesomeIcon icon={faMapMarkerAlt} />;
    case 'start':
    case 'end':
    case 'online':
    case 'offline':
      return null;
    default:
      return null;
  }
};

const MAX_RETRIES = 3;
const RETRY_DELAY = 1000; // 1 second

const fetchAlertWithRetry = async (alertId, retries = 0) => {
  try {
    return await fetchAlertFromApi(alertId);
  } catch (err) {
    if (retries < MAX_RETRIES) {
      console.log(`Retrying fetch for alert ${alertId}, attempt ${retries + 1}`);
      await new Promise((resolve) => setTimeout(resolve, RETRY_DELAY));
      return fetchAlertWithRetry(alertId, retries + 1);
    }
    console.error(`Failed to fetch alert data after ${MAX_RETRIES} attempts:`, err);
    return null;
  }
};

export default function CustomizedTimeline({
  events,
  onEventSelect,
  deviceId,
  batteryLevels,
  eventTypes,
  isShiftInProgress,
  geofences,
}) {
  // console.log('events', events);
  const [selectedItem, setSelectedItem] = useState(0);
  const [clickedItem, setClickedItem] = useState(null);
  const [escalationDescriptions, setEscalationDescriptions] = useState({});
  const [escalationCategory, setEscalationCategory] = useState({});
  const [interactionFlagMap, setInteractionFlagMap] = useState({});
  useEffect(() => {
    if (events.length > 0) {
      onEventSelect(events[0]);
    }
    const fetchDescriptionsAndCategory = async () => {
      const descriptions = await Promise.all(
        events
          .filter((event) => event.type === 'escalation')
          .map(async (event) => {
            const data = await fetchAlertWithRetry(event.alertId);
            if (data) {
              let category = data.CategorizationResult[data.DeployedModelUsed] || 'Escalation';
              // If the category is a comma-separated list, take the first one. This is to ensure backwards
              // compatibility with old data where we had multiple categories in the string
              if (category.includes(',')) {
                category = category.split(',')[0].trim();
              }
              let label = data.Label;
              let description = data.Description;
              if (data.InteractionFlag === 'on') {
                label = data.CategorizationResult['gpt4o-interaction-i'];
                category = label;
                console.log('label', label);
              }
              // If the label is not "Escalation", extract the actual keyword
              else if (label !== 'Escalation' && data.Prompt) {
                const keywordMatch = data.Prompt.match(/keyword\s+(.+?):/i);
                if (keywordMatch) {
                  const keyword = keywordMatch[1].trim();
                  description = `"${keyword.length > 20 ? keyword.substring(0, 17) + '...' : keyword}"`;
                  label = 'Trigger Phrase';
                } else {
                  label = 'Trigger Phrase';
                  description = '';
                }
                category = label; // Use the extracted keyword or "Trigger Phrase" as the category
              }

              return {
                description: description,
                id: event.alertId,
                label: label,
                category: category,
                interactionFlag: data.InteractionFlag,
              };
            }
            return null;
          })
      );
      const interactionFlagMap = descriptions.reduce((acc, description) => {
        if (description) {
          acc[description.id] = description.interactionFlag;
        }
        return acc;
      }, {});
      const descriptionsMap = descriptions.reduce((acc, description) => {
        if (description) {
          acc[description.id] = description.description;
        }
        return acc;
      }, {});
      const categoryMap = descriptions.reduce((acc, description) => {
        if (description) {
          acc[description.id] = description.category;
        }
        return acc;
      }, {});
      setEscalationDescriptions(descriptionsMap);
      setEscalationCategory(categoryMap);
      setInteractionFlagMap(interactionFlagMap);
    };

    fetchDescriptionsAndCategory();
  }, [events, deviceId]);

  const handleItemClick = (index) => {
    setSelectedItem(index);
    setClickedItem(index);
    onEventSelect({ index: index, event: events[index] });
    setTimeout(() => setClickedItem(null), 300);
  };

  const getEventTitle = (event, index, events, geofences) => {
    if (event.type === 'escalation') {
      const alertData = escalationCategory[event.alertId];
      const interactionFlag = interactionFlagMap[event.alertId];
      if (interactionFlag === 'on') {
        return alertData;
      } else if (['Theft Attempt', 'Trespassing'].includes(alertData)) {
        return alertData;
      } else if (alertData === 'Trigger Phrase') {
        const description = escalationDescriptions[event.alertId];
        if (description) {
          const keywordMatch = description.match(/keyword\s+(.+?):/i);
          if (keywordMatch) {
            const keyword = keywordMatch[1].trim();
            return `"${keyword.length > 20 ? keyword.substring(0, 17) + '...' : keyword}"`;
          }
        }
        return 'Trigger Phrase';
      }
      return 'Escalation';
    } else if (event.type === 'locationEnter') {
      return truncateGeofenceId(event.geofenceId, geofences);
    } else if (event.type === 'start') {
      return index === 0 || (index === 1 && events[0].type === 'locationEnter') ? 'Shift Started' : 'Activated';
    } else if (event.type === 'end') {
      return index === events.length - 1 ? 'Shift Ended' : 'Deactivated';
    } else {
      return eventTypes[event.type]?.title || eventTypes.default.title;
    }
  };

  const getEventColor = (event, index, events) => {
    switch (event.type) {
      case 'start':
        return index === 0 || (index === 1 && events[0].type === 'locationEnter') ? '#66bb6a' : '#888';
      case 'end':
        return index === events.length - 1 ? '#f44336' : '#888';
      case 'recordingVideo':
        return '#29b6f6';
      case 'locationEnter':
        return '#ffd700';
      case 'escalation':
        return '#ffa726';
      case 'online':
      case 'offline':
        return '#888';
      default:
        return '#29b6f6';
    }
  };

  const getDeactivationDuration = (currentIndex, events) => {
    const currentEvent = events[currentIndex];
    const nextEvent = events[currentIndex + 1];

    if (currentEvent.type === 'end' && nextEvent) {
      const duration = new Date(nextEvent.timestamp) - new Date(currentEvent.timestamp);
      return formatDuration(duration);
    }
    return null;
  };

  const getLocationEnterDescription = (event, index, events) => {
    const startTime = new Date(event.timestamp);
    let endTime;
    if (event.exitTime) {
      const exitTime = new Date(event.exitTime);
      const lastEventTime = new Date(events[events.length - 1].timestamp);
      endTime = exitTime > lastEventTime ? lastEventTime : exitTime;
    } else {
      endTime = isShiftInProgress ? new Date() : new Date(events[events.length - 1].timestamp);
    }
    return formatShortDuration(endTime - startTime);
  };

  const formatTime = (event) => {
    const dateString = event.timestamp;
    if (!dateString) return 'N/A';
    const date = new Date(dateString);
    return isNaN(date.getTime())
      ? 'Invalid Date'
      : date.toLocaleString('en-US', { hour: '2-digit', minute: '2-digit' });
  };

  const getBatteryIcon = (event, index, events) => {
    let level;
    if (index === 0 && event.type === 'locationEnter' && events[1] && events[1].type === 'start') {
      level = batteryLevels[events[1].timestamp];
    } else {
      level = batteryLevels[event.timestamp];
    }

    if (level === null || level === undefined) return null;

    const percentage = parseInt(level);
    if (isNaN(percentage)) return null;

    return (
      <span className="battery-info">
        <BatteryIcon percentage={percentage} size="0.9em" />
        <span className="battery-percentage">{percentage}%</span>
      </span>
    );
  };

  console.log('Current battery levels state:', batteryLevels); // Debug log

  return (
    <Timeline
      position="right"
      className="timeline-container"
      sx={{
        '& .MuiTimelineItem-root': { minHeight: 'auto' },
        '& .MuiTimelineOppositeContent-root': { flex: 0.3, paddingLeft: '5px' },
        '& .MuiTimelineContent-root': { flex: 0.7, paddingRight: '5px', marginTop: '-4px' },
        '& .MuiTimelineConnector-root': { minHeight: '40px' },
        margin: '0',
      }}
    >
      {events.map((event, index) => (
        <TimelineItem
          key={index}
          onClick={() => handleItemClick(index)}
          sx={{
            cursor: 'pointer',
            opacity: selectedItem === index ? 1 : 0.7,
            transition: 'opacity 0.1s ease',
            '&:hover': { opacity: 1 },
            minWidth: '10px',
          }}
          className={`${selectedItem === index ? 'selected' : ''} ${clickedItem === index ? 'timeline-item-clicked' : ''}`}
        >
          <TimelineOppositeContent color="text.secondary">{formatTime(event)}</TimelineOppositeContent>
          <TimelineSeparator>
            <TimelineDot
              sx={{
                backgroundColor: getEventColor(event, index, events),
                color: 'white',
                padding: 0, // Remove padding
              }}
            >
              {/* {getEventIcon(event, index, events)} */}
            </TimelineDot>

            {index < events.length - 1 && event.type !== 'end' && <TimelineConnector />}
            {event.type === 'end' && (
              <>
                <br />
                <br />{' '}
              </>
            )}
          </TimelineSeparator>
          <TimelineContent>
            <Typography
              variant="h6"
              component="span"
              className={`timeline-item-title ${
                (event.type === 'start' && index !== 0 && !(index === 1 && events[0].type === 'locationEnter')) ||
                (event.type === 'end' && index !== events.length - 1)
                  ? event.type === 'start'
                    ? 'activated'
                    : 'deactivated'
                  : ''
              } `}
            >
              {getEventTitle(event, index, events, geofences)}
              {((index === 0 && event.type === 'start') ||
                (index === 1 && event.type === 'start' && events[0].type === 'locationEnter') ||
                (index === events.length - 1 && event.type === 'end')) && (
                <span className="battery-icon-wrapper">{getBatteryIcon(event, index, events)}</span>
              )}
            </Typography>
            {event.type === 'escalation' && (
              <span className="timeline-item-description">{escalationDescriptions[event.alertId]}</span>
            )}
            {event.type === 'recordingVideo' && (
              <span className="timeline-item-description">{formatShortDuration(parseInt(event.duration))}</span>
            )}
            {event.type === 'end' && index !== events.length - 1 && (
              <div className="timeline-item-duration">{getDeactivationDuration(index, events)}</div>
            )}
            {event.type === 'locationEnter' && (
              <span className="timeline-item-description">{getLocationEnterDescription(event, index, events)}</span>
            )}
          </TimelineContent>
        </TimelineItem>
      ))}
    </Timeline>
  );
}
