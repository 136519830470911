import React, { useState, useEffect } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { ReactComponent as PlixLogo } from '../assets/plix_logo_red.svg'; // Add this import
import '../styles/Loading.css';

const Loading = () => {
  const [showLogout, setShowLogout] = useState(false);
  const { logout } = useAuth0();

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowLogout(true);
    }, 8000);
    return () => clearTimeout(timer);
  }, []);

  const handleLogout = () => {
    localStorage.clear();
    logout({ returnTo: window.location.origin });
  };

  return (
    <div className="loading-container">
      <PlixLogo className="logo-animation" width="50" height="50" />
      <p style={{ color: '#cb2d3e' }}>Loading Plix...</p>
      {showLogout && (
        <div className="logout-section">
          <p style={{ color: '#666', fontSize: '0.9em' }}>Taking longer than expected?</p>
          <button
            onClick={handleLogout}
            className="logout-button"
            style={{
              padding: '8px 16px',
              backgroundColor: '#cb2d3e',
              color: 'white',
              border: 'none',
              borderRadius: '4px',
              cursor: 'pointer',
              marginTop: '10px',
              fontSize: '0.8em',
            }}
          >
            Refresh
          </button>
        </div>
      )}
    </div>
  );
};

export default Loading;
